import React, { useState, useEffect, useCallback, Fragment } from "react";
import { MDBRow, MDBCol, MDBIcon, MDBInput, MDBBtn } from "mdbreact";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
// Mis Componentes
import UsuariosTabla from "./UsuariosTabla";
import ToastMessage from "components/shared/ToastMessage";
import SelectInput from "components/shared/SelectInput";
import Tooltip from "components/shared/Tooltip";
import http from "services/http.service";
import useDebounce from "hooks/useDebounce";
import { mapOptionsToViewModel } from "utils";
// Mis Types
import { Filters, Pagination } from "typings/Tablas";
import { Option } from "typings/General";

export interface UsuariosListaProps {}

const UsuariosLista: React.FC<UsuariosListaProps> = () => {
  const [usuarios, setUsuarios] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search.trim(), 500);
  const [zonasOptions, setZonasOptions] = useState<Option[]>([]);
  const [tipoUsuarioOptions, setTipoUsuarioOptions] = useState<Option[]>([]);
  const [filters, setFilters] = useState<Filters>({
    zona: null,
    tipoUsuario: null,
  });
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    totalSize: 10,
    sizePerPage: 10,
  });

  useEffect(() => {
    const fetchFiltros = async () => {
      try {
        const zonaParams = {
          activo: true,
        };
        const { rows: zonas }: any = await http.get("zonas", { params: zonaParams });
        setZonasOptions(mapOptionsToViewModel(zonas));

        const tipoUsuarioParams = {
          activo: true,
        };
        const { rows: tiposUsuario }: any = await http.get("catalogos/TiposUsuario", { params: tipoUsuarioParams });
        setTipoUsuarioOptions(mapOptionsToViewModel(tiposUsuario));
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ocurrió un error al cargar los filtros, recargue la página.</ToastMessage>);
      }
    };

    fetchFiltros();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchUsuarios();
    // eslint-disable-next-line
  }, [debouncedSearch, pagination.page, filters.zona, filters.tipoUsuario]);

  const fetchUsuarios = async () => {
    const { zona, tipoUsuario } = filters;
    const { page, sizePerPage: limit } = pagination;
    try {
      setIsTableLoading(true);

      const params = {
        ...(debouncedSearch && { search: debouncedSearch }),
        ...(zona ? { idZona: zona } : {}),
        ...(tipoUsuario ? { idTipoUsuario: tipoUsuario } : {}),
        limit,
        page,
      };
      const { rows: usuariosList, count: totalSize }: any = await http.get("usuarios", { params });
      setUsuarios(mapUsuariosToViewModel(usuariosList));
      setPagination({ ...pagination, totalSize });

      setIsTableLoading(false);
    } catch (error) {
      setIsTableLoading(false);
      toast.error(<ToastMessage type={"error"}>Ocurrió un error al cargar la lista de usuarios.</ToastMessage>);
    }
  };

  const mapUsuariosToViewModel = (usuarios: any[]) => {
    return usuarios.map(usuario => {
      return {
        idUsuario: usuario.idUsuario,
        nombreCompleto: `${usuario.nombres} ${usuario.primerApellido} ${usuario.segundoApellido}`,
        email: usuario.email,
        activo: usuario.activo,
        tipoUsuario: usuario.tipoUsuario.nombre,
      };
    });
  };

  const handleSearchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(input.value);
  };

  const handleRefresh = () => {
    fetchUsuarios();
  };

  const handleCleanFilters = () => {
    setSearch("");
    setFilters({
      zona: null,
      tipoUsuario: null,
    });
  };

  const handleChangeSelect =
    inputName =>
    (option, { action }) => {
      setFilters({
        ...filters,
        [inputName]: option.value,
      });
    };

  const handleToggleEstado = useCallback(
    async (id: number, activo: boolean, index: number) => {
      try {
        const result = await Swal.fire({
          title: `¿Estás seguro que deseas ${activo ? "desactivar" : "activar"} este usuario?`,
          text: "Puedes revertir este cambio en cualquier momento presionando el mismo boton",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-success waves-effect waves-light text-capitalize",
            cancelButton: "btn btn-danger waves-effect waves-light text-capitalize ml-2",
          },
          buttonsStyling: false,
        });
        if (result.value) {
          await http.put(`usuarios/${id}/${activo ? "desactivar" : "activar"}`);
          let usuariosAux = [...usuarios];

          // Actualizar UI
          usuariosAux[index].activo = !usuariosAux[index].activo;
          setUsuarios(usuariosAux);
        }
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al cambiar el estado, intente de nuevo.</ToastMessage>);
      }
    },
    [usuarios]
  );

  const handleTableChange = useCallback(
    (type, { page, sizePerPage }) => {
      setPagination({
        ...pagination,
        page,
      });
    },
    [pagination]
  );
  return (
    <Fragment>
      <div className="table-filters py-2">
        <MDBRow className="">
          <MDBCol md="9">
            <MDBInput
              className="m-0"
              label="Buscar por nombre, ID, correo electrónico"
              outline
              icon="search"
              iconSize="lg"
              onChange={handleSearchChange}
              value={search}
            />
          </MDBCol>
          <MDBCol md="3">
            <div style={{ marginTop: "0.6rem" }}>
              <Tooltip title="Actualizar" placement="top">
                <MDBBtn size="sm" color="danger" onClick={handleRefresh}>
                  <MDBIcon size="2x" icon="sync" fixed />
                </MDBBtn>
              </Tooltip>
              <Tooltip title="Limpiar Filtros" placement="top">
                <MDBBtn size="sm" color="danger" onClick={handleCleanFilters}>
                  <MDBIcon size="2x" icon="eraser" fixed />
                </MDBBtn>
              </Tooltip>
            </div>
          </MDBCol>
        </MDBRow>

        {/* FILTROS */}
        <MDBRow className="mb-3 mt-0 mx-0">
          <MDBCol className="pl-0" md="3">
            <SelectInput
              name="zona"
              placeholder="Zona"
              options={zonasOptions}
              handleCustomSelect={handleChangeSelect}
              value={filters.zona}
            />
          </MDBCol>
          <MDBCol className="pl-0" md="3">
            <SelectInput
              name="tipoUsuario"
              placeholder="Tipo"
              options={tipoUsuarioOptions}
              isSearchable={false}
              handleCustomSelect={handleChangeSelect}
              value={filters.tipoUsuario}
            />
          </MDBCol>
        </MDBRow>
      </div>

      <UsuariosTabla
        usuarios={usuarios}
        isTableLoading={isTableLoading}
        pagination={pagination}
        handleTableChange={handleTableChange}
        handleToggleEstado={handleToggleEstado}
      />
    </Fragment>
  );
};

export default UsuariosLista;

