import React, { useState, useEffect, Fragment, useCallback } from "react";
import { MDBRow, MDBCol, MDBIcon, MDBInput, MDBBtn } from "mdbreact";
import { toast } from "react-toastify";
// Mis Componentes
import ToastMessage from "components/shared/ToastMessage";
import SelectInput from "components/shared/SelectInput";
import Tooltip from "components/shared/Tooltip";
import Can from "components/shared/Can";
import http from "services/http.service";
import useDebounce from "hooks/useDebounce";
import { getCurrentUser } from "services/authentication.service";
import { getCosto, mapOptionsToViewModel } from "utils";
// Mis Types
import { Filters, Pagination } from "typings/Tablas";
import { Option } from "typings/General";
import ViajesTabla from "./ViajesTabla";

export interface ViajesListaProps {}

const ViajesLista: React.FC<ViajesListaProps> = () => {
  const user = getCurrentUser();
  const [viajes, setViajes] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search.trim(), 500);
  const [zonasOptions, setZonasOptions] = useState<Option[]>([]);
  const [gruposOptions, setGruposOptions] = useState<Option[]>([]);
  const [tipoChoferOptions, setTipoChoferOptions] = useState<Option[]>([]);
  const [filters, setFilters] = useState<Filters>({
    zona: user.zona ? user.zona : process.env.REACT_APP_ZONA_DEFAULT ? parseInt(process.env.REACT_APP_ZONA_DEFAULT) : null,
    grupo: null,
    tipoChofer: null,
  });
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    totalSize: 10,
    sizePerPage: 10,
  });

  // Obtener informacion de los filtros
  useEffect(() => {
    const fetchFiltros = async () => {
      try {
        const params = {
          activo: true,
        };
        const { rows: zonas }: any = await http.get("zonas", { params });
        setZonasOptions(mapOptionsToViewModel(zonas));

        const { rows: tiposChofer }: any = await http.get("catalogos/TiposChofer");
        setTipoChoferOptions(mapOptionsToViewModel(tiposChofer));
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ocurrió un error al cargar los filtros, recargue la página.</ToastMessage>);
      }
    };
    fetchFiltros();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchGrupos = async () => {
      try {
        const params = {
          ...(filters.zona ? { idZona: filters.zona } : { idZona: user.zona }),
        };
        const { rows: grupos }: any = await http.get("grupos", { params });
        setGruposOptions(mapOptionsToViewModel(grupos));
      } catch (error) {}
    };

    fetchGrupos();
    // eslint-disable-next-line
  }, [filters.zona]);

  // Obtener informacion de los viajes
  useEffect(() => {
    fetchViajes();
    // eslint-disable-next-line
  }, [debouncedSearch, pagination.page, filters.zona, filters.grupo, filters.tipoChofer]);

  const fetchViajes = async () => {
    const { zona, grupo, tipoChofer } = filters;
    const { page, sizePerPage: limit } = pagination;

    try {
      setIsTableLoading(true);

      const params = {
        ...(debouncedSearch && { search: debouncedSearch }),
        ...(zona && { idZona: zona }),
        ...(grupo && { idGrupo: grupo }),
        ...(tipoChofer && { idTipoChofer: tipoChofer }),
        order: "desc",
        limit,
        page,
      };
      const { rows: viajesList, count: totalSize }: any = await http.get("viajes", { params });
      setViajes(mapViajesToViewModel(viajesList));
      setPagination({ ...pagination, totalSize });

      setIsTableLoading(false);
    } catch (error) {
      setIsTableLoading(false);
      toast.error(<ToastMessage type={"error"}>Ocurrió un error al cargar la lista de viajes.</ToastMessage>);
    }
  };

  const mapViajesToViewModel = (viajes: any[]) => {
    return viajes.map(viaje => {
      return {
        idViaje: viaje.idViaje,
        estatus: {
          ...viaje.estatusViaje,
          cancelado: viaje.cancelado,
        },
        nombreChofer: `${viaje.chofer.nombres} ${viaje.chofer.primerApellido} ${viaje.chofer.segundoApellido}`,
        nombreCliente: `${viaje.cliente.nombre}`,
        monto: getCosto(viaje),
      };
    });
  };

  const handleSearchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(input.value);
  };

  const handleRefresh = () => fetchViajes();

  const handleCleanFilters = () => {
    setSearch("");
    setFilters({
      zona: user.zona ? user.zona : process.env.REACT_APP_ZONA_DEFAULT,
      grupo: null,
      tipoChofer: null,
    });
  };

  const handleChangeSelect = useCallback(
    inputName =>
      (option, { action }) => {
        setFilters({
          ...filters,
          [inputName]: option.value,
        });
      },
    [filters]
  );

  const handleTableChange = useCallback(
    (type, { page, sizePerPage }) => {
      setPagination({
        ...pagination,
        page,
      });
    },
    [pagination]
  );

  return (
    <Fragment>
      <div className="table-filters py-2">
        <MDBRow className="">
          <MDBCol md="9">
            <MDBInput
              className="m-0"
              label="Buscar por cliente, chofer, ID viaje, teléfono..."
              outline
              icon="search"
              iconSize="lg"
              onChange={handleSearchChange}
              value={search}
            />
          </MDBCol>
          <MDBCol md="3">
            <div style={{ marginTop: "0.6rem" }}>
              <Tooltip title="Actualizar" placement="top">
                <MDBBtn size="sm" color="danger" onClick={handleRefresh}>
                  <MDBIcon size="2x" icon="sync" fixed />
                </MDBBtn>
              </Tooltip>
              <Tooltip title="Limpiar Filtros" placement="top">
                <MDBBtn size="sm" color="danger" onClick={handleCleanFilters}>
                  <MDBIcon size="2x" icon="eraser" fixed />
                </MDBBtn>
              </Tooltip>
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow className="mb-3 mt-0 mx-0">
          <Can I="filterByZona" of="Viajes">
            {() => (
              <MDBCol className="pl-0" md="3">
                <SelectInput
                  name="zona"
                  placeholder="Zona"
                  options={zonasOptions}
                  handleCustomSelect={handleChangeSelect}
                  value={filters.zona}
                />
              </MDBCol>
            )}
          </Can>
          <MDBCol className="pl-0" md="3">
            <SelectInput
              name="grupo"
              placeholder="Grupo"
              options={gruposOptions}
              handleCustomSelect={handleChangeSelect}
              value={filters.grupo}
            />
          </MDBCol>
          <MDBCol className="pl-0" md="3">
            <SelectInput
              name="tipoChofer"
              placeholder="Tipo Chofer"
              options={tipoChoferOptions}
              isSearchable={false}
              handleCustomSelect={handleChangeSelect}
              value={filters.tipoChofer}
            />
          </MDBCol>
        </MDBRow>
      </div>
      <ViajesTabla viajes={viajes} isTableLoading={isTableLoading} pagination={pagination} handleTableChange={handleTableChange} />
    </Fragment>
  );
};

export default ViajesLista;

