import React, { useState, useEffect, useRef } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBCollapse, MDBMedia, MDBInput, MDBBadge } from "mdbreact";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import Swal from "sweetalert2";
import { Map, TileLayer, Polygon } from "react-leaflet";
import moment from "moment";
// Mis Componentes
import { toast } from "react-toastify";
import ToastMessage from "components/shared/ToastMessage";
import SelectInput from "components/shared/SelectInput";
import Tooltip from "components/shared/Tooltip";
import useDebounce from "hooks/useDebounce";
import useScrollTop from "hooks/useScrollTop";
import { mapOptionsToViewModel } from "utils";
import http from "services/http.service";
// Mis Types
import { Filters, Pagination } from "typings/Tablas";
import { Option } from "typings/General";

interface Estadisticas {
  viajes: {
    totalPedidos: number;
    finalizados: string;
    endCash: string;
    endCard: string;
    endMonedero: string;
    totalOdometro: number;
  };
  peticiones?: {
    total: number;
    aceptadas: string;
    ignoradas: string;
    rechazadas: string;
  };
  horasConexion?: string;
  cancelaciones: {
    peticionesCanceladasCliente: string;
    viajesCancelados: string;
    cancelacionesChofer: string;
    cancelacionesCliente: string;
    cancelacionesAdmin: string;
  };
}

export interface GrupoDetalleProps extends RouteComponentProps {}

const GrupoDetalles: React.FC<GrupoDetalleProps> = ({ match }) => {
  const grupoId = match.params["id"];
  const [collapseId, setCollapseId] = useState<string>("");
  const [grupo, setGrupo] = useState<any>();
  const [estadisticas, setEstadisticas] = useState<Estadisticas>({
    viajes: {
      totalPedidos: 0,
      finalizados: "-",
      endCash: "-",
      endCard: "-",
      endMonedero: "-",
      totalOdometro: 0,
    },
    peticiones: {
      total: 0,
      aceptadas: "-",
      ignoradas: "-",
      rechazadas: "-",
    },
    horasConexion: "-",
    cancelaciones: {
      peticionesCanceladasCliente: "-",
      viajesCancelados: "-",
      cancelacionesChofer: "-",
      cancelacionesCliente: "-",
      cancelacionesAdmin: "-",
    },
  });

  // TABLA
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search.trim(), 500);
  const [tipoChoferOptions, setTipoChoferOptions] = useState<Option[]>([]);
  const estatusOptions = [
    { value: "false", label: "Desactivado" },
    { value: "true", label: "Activo" },
  ];
  const [miembros, setMiembros] = useState<any[]>([]);
  const [filters, setFilters] = useState<Filters>({
    tipoChofer: null,
    estatus: null,
  });
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    totalSize: 10,
    sizePerPage: 10,
  });
  const columns = [
    {
      dataField: "idChofer",
      text: "ID",
      headerAlign: "center",
      align: "center",
      headerClasses: "font-weight-bold text-dark",
    },
    {
      dataField: "nombre",
      text: "Nombre",
      headerAlign: "center",
      align: "center",
      headerClasses: "font-weight-bold text-dark",
    },
    {
      dataField: "email",
      text: "Correo",
      headerAlign: "center",
      align: "center",
      headerClasses: "font-weight-bold text-dark",
    },
    {
      dataField: "telefono",
      text: "Teléfono",
      headerAlign: "center",
      align: "center",
      headerClasses: "font-weight-bold text-dark",
    },
  ];

  // MAPA
  const mapa = useRef<any>();
  const [map, setMap] = useState<any>({
    position: [29.0729673, -110.9559192],
    zoom: 12,
    polygonPoints: [],
  });

  // SmoothScroll
  useScrollTop();

  // Obtener informacion del grupo
  useEffect(() => {
    const fetchTiposChofer = async () => {
      try {
        const { rows }: any = await http.get("catalogos/TiposChofer");
        setTipoChoferOptions(mapOptionsToViewModel(rows));
      } catch (error) {}
    };

    const fetchGrupo = async () => {
      try {
        const grupoData: any = await http.get(`grupos/${grupoId}`);
        setGrupo(grupoData);
        // Mappear puntos del poligono
        const polygonPoints = grupoData.zona.poligonoZona.coordinates[0].map(point => Object.values(point).reverse());
        setMap({
          ...map,
          polygonPoints,
        });
      } catch (error) {
        if ((error.status && error.status !== 500) || error.type) {
          toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al obtener los datos del grupo, intente de nuevo.</ToastMessage>);
        }
      }
    };
    const fetchEstadisticas = async () => {
      try {
        const estadisticasData: any = await http.get(`choferes/${grupoId}/estadisticas`);
        setEstadisticas(estadisticasData);
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al obtener las estadisticas, intente de nuevo.</ToastMessage>);
      }
    };

    fetchEstadisticas();
    fetchGrupo();
    fetchTiposChofer();
    // eslint-disable-next-line
  }, [grupoId]);

  // Obtener los miembros del grupo
  useEffect(() => {
    fetchMiembrosGrupo();
    // eslint-disable-next-line
  }, [debouncedSearch, pagination.page, filters.tipoChofer, filters.estatus]);

  // Centrar la zona en el mapa
  useEffect(() => {
    if (map.polygonPoints.length) {
      mapa.current.leafletElement.fitBounds([map.polygonPoints]);
    }
  }, [map.polygonPoints]);

  const fetchMiembrosGrupo = async () => {
    const { tipoChofer, estatus } = filters;
    const { page, sizePerPage: limit } = pagination;
    try {
      setIsTableLoading(true);

      const params = {
        ...(search ? { search } : {}),
        ...(tipoChofer ? { idTipoChofer: tipoChofer } : {}),
        ...(estatus !== null ? { activo: estatus } : {}),
        limit,
        page,
      };
      const { rows: miembrosList, count: totalSize }: any = await http.get(`grupos/${grupoId}/choferes`, { params });
      setMiembros(mapMiembrosGrupoToViewModel(miembrosList));
      setPagination({ ...pagination, totalSize });

      setIsTableLoading(false);
    } catch (error) {
      setIsTableLoading(false);
      toast.error(<ToastMessage type={"error"}>Ocurrió un error al cargar la lista de choferes.</ToastMessage>);
    }
  };

  const mapMiembrosGrupoToViewModel = (miembros: any[]) => {
    return miembros.map(miembro => {
      return {
        idChofer: miembro.idChofer,
        nombre: `${miembro.nombres} ${miembro.primerApellido} ${miembro.segundoApellido}`,
        email: miembro.email,
        telefono: miembro.telefono,
        activo: miembro.activo,
      };
    });
  };

  const handleToggleCard = (id: string) => setCollapseId(prevId => (prevId !== id ? id : ""));

  const handleToggleEstado = async () => {
    const result = await Swal.fire({
      title: `¿Estás seguro que deseas ${grupo.activo ? "desactivar" : "activar"} el grupo?`,
      text: "Puedes revertir este cambio en cualquier momento presionando el mismo boton",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      customClass: {
        confirmButton: "btn btn-success waves-effect waves-light text-capitalize",
        cancelButton: "btn btn-danger waves-effect waves-light text-capitalize ml-2",
      },
      buttonsStyling: false,
    });
    if (result.value) {
      try {
        await http.put(`grupos/${grupoId}/${grupo.activo ? "desactivar" : "activar"}`);
        setGrupo({
          ...grupo,
          activo: !grupo.activo,
        });
        toast.success(<ToastMessage type={"success"}>La acción fue realizada con éxito.</ToastMessage>);
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al cambiar el estado, intente de nuevo.</ToastMessage>);
      }
    }
  };

  const handleSearchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(input.value);
  };

  const handleRefresh = () => {
    fetchMiembrosGrupo();
  };

  const handleCleanFilters = () => {
    setSearch("");
    setFilters({
      tipoChofer: null,
      estatus: null,
    });
  };

  const handleChangeSelect =
    inputName =>
    (option, { action }) => {
      setFilters({
        ...filters,
        [inputName]: option.value,
      });
    };

  const handleTableChange = (type, { page, sizePerPage }) => {
    setPagination({
      ...pagination,
      page,
    });
  };

  return (
    <section id="grupos">
      <MDBRow className="mb-4">
        <MDBCol className="align-self-center">
          <header>
            <h3 className="mb-0">
              <Link className="text-dark" to="/r/grupos">
                <MDBIcon className="mr-3" icon="arrow-left" />
              </Link>
              Detalles de grupo
            </h3>
          </header>
        </MDBCol>
        <MDBCol className="text-right">
          {grupo && grupo.activo && (
            <Link to={`/r/grupos/${grupoId}/editar`}>
              <MDBBtn color="indigo" className="text-capitalize btn-top-action">
                <MDBIcon className="mr-2" icon="edit" size="lg" />
                Editar
              </MDBBtn>
            </Link>
          )}
          <MDBBtn
            color={grupo && grupo.activo ? "success" : "danger"}
            className="text-capitalize btn-top-action"
            onClick={handleToggleEstado}>
            <MDBIcon className="mr-2" icon="power-off" size="lg" />
            {grupo && grupo.activo ? "Activo" : "Desactivado"}
          </MDBBtn>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol md="8">
          {/* INFORMACION DE GRUPO */}
          <MDBCard className="mb-4">
            <div className="card-header card-header-shadow bg-dark-orange text-white font-weight-bold">
              <MDBIcon size="lg" far icon="id-card" className="mr-3" />
              Información del grupo
            </div>
            <MDBCardBody className="p-4">
              <MDBRow>
                <MDBCol md="4">
                  <label className="font-weight-medium" htmlFor="City">
                    Nombre
                  </label>
                  <p>{grupo ? grupo.nombre : "N/A"}</p>
                </MDBCol>
                <MDBCol md="4">
                  <label className="font-weight-medium" htmlFor="City">
                    Lider de grupo
                  </label>
                  <p>
                    {grupo && grupo.choferLider
                      ? `${grupo.choferLider.nombres} ${grupo.choferLider.primerApellido} ${grupo.choferLider.segundoApellido}`
                      : "N/A"}
                  </p>
                </MDBCol>
                <MDBCol md="4">
                  <label className="font-weight-medium" htmlFor="City">
                    Estado
                  </label>
                  <p>
                    {grupo ? (
                      grupo.activo ? (
                        <MDBBadge color="success px-4">Activo</MDBBadge>
                      ) : (
                        <MDBBadge color="danger">Desactivado</MDBBadge>
                      )
                    ) : (
                      "N/A"
                    )}
                  </p>
                </MDBCol>
                <MDBCol md="4">
                  <label className="font-weight-medium" htmlFor="City">
                    Zona de operación
                  </label>
                  <p>{grupo ? grupo.zona.nombreZona : "N/A"}</p>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>

          {/* TABLA DE MIEMBROS */}
          <MDBCard className="mb-4">
            <div
              className="card-header card-header-shadow bg-dark-orange text-white font-weight-bold cursor-pointer d-flex justify-content-between align-items-center"
              onClick={() => handleToggleCard("miembros-grupo")}>
              <span>
                <MDBIcon size="lg" icon="car-alt" className="mr-3" />
                Choferes en grupo
              </span>
              <span>
                <MDBIcon size="lg" icon={collapseId === "miembros-grupo" ? "minus" : "plus"} />
              </span>
            </div>
            <MDBCollapse id="miembros-grupo" isOpen={collapseId}>
              <MDBCardBody className="px-4 pb-4 pt-2">
                <div className="table-filters">
                  <MDBRow className="">
                    <MDBCol md="9">
                      <MDBInput
                        className="m-0"
                        label="Buscar por nombre, clave, correo electrónico"
                        outline
                        icon="search"
                        iconSize="lg"
                        onChange={handleSearchChange}
                        value={search}
                      />
                    </MDBCol>
                    <MDBCol md="3">
                      <div style={{ marginTop: "0.6rem" }}>
                        <Tooltip title="Actualizar" placement="top">
                          <MDBBtn size="sm" color="danger" onClick={handleRefresh}>
                            <MDBIcon size="2x" icon="sync" fixed />
                          </MDBBtn>
                        </Tooltip>
                        <Tooltip title="Limpiar Filtros" placement="top">
                          <MDBBtn size="sm" color="danger" onClick={handleCleanFilters}>
                            <MDBIcon size="2x" icon="eraser" fixed />
                          </MDBBtn>
                        </Tooltip>
                      </div>
                    </MDBCol>
                  </MDBRow>

                  {/* FILTROS */}
                  <MDBRow className="mb-3 mt-0 mx-0">
                    <MDBCol className="pl-0" md="4">
                      <SelectInput
                        name="tipoChofer"
                        placeholder="Tipo chofer"
                        options={tipoChoferOptions}
                        isSearchable={false}
                        handleCustomSelect={handleChangeSelect}
                        value={filters.tipoChofer}
                      />
                    </MDBCol>
                    <MDBCol className="pl-0" md="4">
                      <SelectInput
                        name="estatus"
                        placeholder="Estado"
                        options={estatusOptions}
                        isSearchable={false}
                        handleCustomSelect={handleChangeSelect}
                        value={filters.estatus}
                      />
                    </MDBCol>
                  </MDBRow>
                </div>
                <BootstrapTable
                  bootstrap4
                  condensed
                  classes="miembros-table"
                  keyField="idChofer"
                  data={miembros}
                  columns={columns}
                  remote={{
                    pagination: true,
                  }}
                  loading={isTableLoading}
                  overlay={overlayFactory({ spinner: true, background: "rgba(192,192,192,0.3)" })}
                  hover
                  bordered={false}
                  noDataIndication="No se encontraron registros."
                  rowClasses={"font-weight-bold"}
                  pagination={paginationFactory({
                    page: pagination.page, // Specify the current page.
                    totalSize: pagination.totalSize, // Total data size.
                    sizePerPage: pagination.sizePerPage, // Specify the size per page.
                    withFirstAndLast: false, // hide the going to first and last page button
                    alwaysShowAllBtns: true, // always show the next and previous page button
                    prePageText: "Anterior",
                    nextPageText: "Siguiente",
                    hideSizePerPage: true, // hide the size per page dropdown
                    hidePageListOnlyOnePage: true,
                  })}
                  onTableChange={handleTableChange}
                />
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          {/* ZONA DE OPERACION */}
          <MDBCard className="mb-4">
            <div className="card-header card-header-shadow bg-dark-orange text-white font-weight-bold cursor-pointer d-flex justify-content-between align-items-center">
              <span>
                <MDBIcon size="lg" icon="map" className="mr-3" />
                Zona de operación
              </span>
              <span>
                <MDBIcon size="lg" icon="minus" />
              </span>
            </div>

            <MDBCardBody className="p-0">
              {/* MAPA */}
              <Map ref={mapa} boxZoom={false} doubleClickZoom={false} scrollWheelZoom={false}>
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Polygon positions={map.polygonPoints} />
              </Map>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        {/* ESTADISTICAS */}
        <MDBCol md="4">
          <MDBCard className="mb-4">
            <div className="card-header card-header-shadow bg-dark-orange text-white">
              <MDBIcon size="lg" icon="chart-pie" className="mr-3" />
              Estadisticas
            </div>
            <MDBCardBody className="p-3">
              <p className="font-weight-medium">Hoy</p>
              <h4>{moment().format("LL")}</h4>
            </MDBCardBody>
          </MDBCard>

          {/* HORAS DE CONEXION */}
          <MDBCard className="mb-4">
            <MDBCardBody>
              <MDBMedia>
                <MDBMedia className="mr-4">
                  <MDBIcon className="card-icon bg-info" icon="power-off" size="3x" />
                </MDBMedia>
                <MDBMedia middle body>
                  <p>Horas de conexión</p>
                  <h3 className="font-weight-medium mb-0">{estadisticas.horasConexion ? estadisticas.horasConexion : "--:--:--"}</h3>
                </MDBMedia>
              </MDBMedia>
            </MDBCardBody>
          </MDBCard>

          {/* BALANCE DE VIAJES */}
          <MDBCard className="mb-4">
            <MDBCardBody>
              <MDBMedia>
                <MDBMedia className="mr-4">
                  <MDBIcon style={{ width: "94px" }} className="card-icon bg-success text-center" icon="dollar-sign" size="3x" />
                </MDBMedia>
                <MDBMedia middle body>
                  <p>Ganancias</p>
                  <h3 className="font-weight-medium mb-0">N/A</h3>
                </MDBMedia>
              </MDBMedia>
            </MDBCardBody>
          </MDBCard>

          {/* SOLICITUDES */}
          <MDBCard className="mb-4">
            <MDBCardBody>
              <MDBMedia>
                <MDBMedia className="mr-4">
                  <MDBIcon className="card-icon bg-warning" icon="envelope" size="3x" />
                </MDBMedia>
                <MDBMedia middle body>
                  <p>Solicitudes</p>
                  <h3 className="font-weight-medium mb-0">{estadisticas.peticiones ? estadisticas.peticiones.total : "N/A"}</h3>
                </MDBMedia>
              </MDBMedia>

              <MDBRow className="mt-4">
                {/* <MDBCol className='font-weight-medium' md='9'>
                           Vistas:
                        </MDBCol>
                        <MDBCol className='mb-3 text-right' md='3'>
                           --
                        </MDBCol> */}
                <MDBCol className="font-weight-medium" md="9">
                  Rechazadas por chofer:
                </MDBCol>
                <MDBCol className="mb-3 text-right" md="3">
                  {estadisticas.peticiones ? estadisticas.peticiones.rechazadas : "N/A"}
                </MDBCol>
                <MDBCol className="font-weight-medium" md="9">
                  Canceladas por cliente:
                </MDBCol>
                <MDBCol className="mb-3 text-right" md="3">
                  {estadisticas.peticiones ? estadisticas.peticiones.ignoradas : "N/A"}
                </MDBCol>
                <MDBCol className="font-weight-medium" md="9">
                  Ganadas por chofer:
                </MDBCol>
                <MDBCol className="mb-3 text-right" md="3">
                  {estadisticas.peticiones ? estadisticas.peticiones.aceptadas : "N/A"}
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>

          {/* VIAJES */}
          <MDBCard className="mb-4">
            <MDBCardBody>
              <MDBMedia>
                <MDBMedia className="mr-4">
                  <MDBIcon className="card-icon bg-primary" icon="route" size="3x" />
                </MDBMedia>
                <MDBMedia middle body>
                  <p>Viajes</p>
                  <h3 className="font-weight-medium mb-0">{estadisticas.viajes.totalPedidos}</h3>
                </MDBMedia>
              </MDBMedia>

              <MDBRow className="mt-4">
                <MDBCol className="font-weight-medium" md="9">
                  Viajes finalizados:
                </MDBCol>
                <MDBCol className="mb-3 text-right" md="3">
                  {estadisticas.viajes.finalizados}
                </MDBCol>
                <MDBCol className="font-weight-medium" md="9">
                  Pagados con tarjeta:
                </MDBCol>
                <MDBCol className="mb-3 text-right" md="3">
                  {estadisticas.viajes.endCard}
                </MDBCol>
                <MDBCol className="font-weight-medium" md="9">
                  Pagados con efectivo:
                </MDBCol>
                <MDBCol className="mb-3 text-right" md="3">
                  {estadisticas.viajes.endCash}
                </MDBCol>
                {/* <MDBCol className="font-weight-medium" md="9">
                  Pagados con monedero:
                </MDBCol>
                <MDBCol className="mb-3 text-right" md="3">
                  {estadisticas.viajes.endMonedero}
                </MDBCol> */}
                {/* <MDBCol className="font-weight-medium" md="9">
                  Odómetro:
                </MDBCol>
                <MDBCol className="mb-3 text-right" md="3">
                  {estadisticas.viajes.totalOdometro}
                </MDBCol> */}
              </MDBRow>
            </MDBCardBody>
          </MDBCard>

          {/* TODO: Faltan todos los datos desglosados por estatus del viaje */}
          {/* CANCELACIONES */}
          <MDBCard>
            <MDBCardBody>
              <MDBMedia>
                <MDBMedia className="mr-4">
                  <MDBIcon className="card-icon bg-danger" icon="ban" size="3x" />
                </MDBMedia>
                <MDBMedia middle body>
                  <p>Cancelaciones</p>
                  <h3 className="font-weight-medium mb-0">{estadisticas.cancelaciones.viajesCancelados}</h3>
                </MDBMedia>
              </MDBMedia>

              <p className="text-muted font-weight-medium mt-4 mb-0">Por clientes</p>
              <hr className="mt-0" />
              <MDBRow>
                <MDBCol className="font-weight-medium" md="9">
                  Chofer en camino:
                </MDBCol>
                <MDBCol className="mb-3 text-right" md="3">
                  N/A
                </MDBCol>
                <MDBCol className="font-weight-medium" md="9">
                  Chofer a punto de llegar:
                </MDBCol>
                <MDBCol className="mb-3 text-right" md="3">
                  N/A
                </MDBCol>
                <MDBCol className="font-weight-medium" md="9">
                  Llegó con el cliente:
                </MDBCol>
                <MDBCol className="mb-3 text-right" md="3">
                  N/A
                </MDBCol>
              </MDBRow>

              <p className="text-muted font-weight-medium mt-2 mb-0">Por chofer</p>
              <hr className="mt-0" />
              <MDBRow>
                <MDBCol className="font-weight-medium" md="9">
                  Chofer en camino:
                </MDBCol>
                <MDBCol className="mb-3 text-right" md="3">
                  N/A
                </MDBCol>
                <MDBCol className="font-weight-medium" md="9">
                  Chofer a punto de llegar:
                </MDBCol>
                <MDBCol className="mb-3 text-right" md="3">
                  N/A
                </MDBCol>
                <MDBCol className="font-weight-medium" md="9">
                  Llegó con el cliente:
                </MDBCol>
                <MDBCol className="mb-3 text-right" md="3">
                  N/A
                </MDBCol>
              </MDBRow>

              <p className="text-muted font-weight-medium mt-2 mb-0">Por administrador</p>
              <hr className="mt-0" />
              <MDBRow>
                <MDBCol className="font-weight-medium" md="9">
                  Chofer en camino:
                </MDBCol>
                <MDBCol className="mb-3 text-right" md="3">
                  N/A
                </MDBCol>
                <MDBCol className="font-weight-medium" md="9">
                  Chofer a punto de llegar:
                </MDBCol>
                <MDBCol className="mb-3 text-right" md="3">
                  N/A
                </MDBCol>
                <MDBCol className="font-weight-medium" md="9">
                  Llegó con el cliente:
                </MDBCol>
                <MDBCol className="mb-3 text-right" md="3">
                  N/A
                </MDBCol>
                <MDBCol className="font-weight-medium" md="9">
                  Viaje iniciado:
                </MDBCol>
                <MDBCol className="mb-3 text-right" md="3">
                  N/A
                </MDBCol>
                <MDBCol className="font-weight-medium" md="9">
                  Viaje a punto de terminar:
                </MDBCol>
                <MDBCol className="mb-3 text-right" md="3">
                  N/A
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </section>
  );
};

export default GrupoDetalles;

