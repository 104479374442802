import React, { useState, useEffect, Fragment } from 'react';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, MDBIcon } from 'mdbreact';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Bar } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import moment from 'moment';
// Mis Componentes
import ToastMessage from 'components/shared/ToastMessage';
import PeriodPicker from 'components/shared/PeriodPicker';
import http from 'services/http.service';

export interface SaldosStatsProps { }

const SaldosStats: React.SFC<SaldosStatsProps> = () => {
   // TABS
   const [tabValue, setTabValue] = useState<number>(0);

   const [dates, setDates] = useState<any>({
      startingDate: moment()
         .startOf('week')
         .toISOString(),
      endingDate: moment()
         .endOf('week')
         .toISOString()
   });
   const [barChart, setBarChart] = useState<any>({
      labels: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
      datasets: [
         {
            label: 'Horas',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: ['#FFA600', '#FFA600', '#FFA600', '#FFA600', '#FFA600', '#FFA600', '#FFA600'],
            borderColor: ['#FFA600', '#FFA600', '#FFA600', '#FFA600', '#FFA600', '#FFA600', '#FFA600']
         }
      ]
   });
   const barChartOptions = {
      maintainAspectRatio: false,
      scales: {
         xAxes: [
            {
               barPercentage: 1,
               gridLines: {
                  display: false
               }
            }
         ],
         yAxes: [
            {
               gridLines: {
                  display: true,
                  color: 'rgba(0, 0, 0, 0.1)'
               },
               ticks: {
                  beginAtZero: true
               }
            }
         ]
      },
      legend: {
         display: false
      }
   };

   useEffect(() => {
      const fetchStats = async () => {
         const { startingDate, endingDate } = dates;

         try {
            const params = {};
            const stats: any = await http.get('grupos', { params });
         } catch (error) {
            toast.error(<ToastMessage type={'error'}>Ocurrió un error al cargar las estadísticas.</ToastMessage>);
         }
      };
      fetchStats();
   }, [dates]);

   const handleBtnAnterior = () => {
      const beforeStartingDate = moment(dates.startingDate)
         .subtract(1, 'week')
         .toISOString();
      const beforeEndingDate = moment(dates.endingDate)
         .subtract(1, 'week')
         .toISOString();

      setDates({ startingDate: beforeStartingDate, endingDate: beforeEndingDate });
   };

   const handleBtnSiguiente = () => {
      const afterStartingDate = moment(dates.startingDate)
         .add(1, 'week')
         .toISOString();
      const afterEndingDate = moment(dates.endingDate)
         .add(1, 'week')
         .toISOString();

      setDates({ startingDate: afterStartingDate, endingDate: afterEndingDate });
   };

   const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      setTabValue(newValue);
   };

   return (
      <Fragment>
         {/* TODO: Logica para el filtro de fechas en la tabla */}
         <div className='mb-2'>
            <PeriodPicker
               onClickBtnAnterior={handleBtnAnterior}
               onClickBtnSiguiente={handleBtnSiguiente}
               isBtnSiguienteDisabled={moment().isBetween(moment(dates.startingDate), moment(dates.endingDate))}
               values={[dates.startingDate, dates.endingDate]}
            />
         </div>

         <MDBRow className='mb-4 align-items-center'>
            <MDBCol md='6'>
               <MDBCard>
                  <div className='card-header card-header-shadow bg-dark-orange text-white'>
                     <MDBIcon size='lg' icon='coins' className='mr-3' />
                     Estadisticas
                  </div>
                  <MDBCardBody>
                     <MDBRow className='mb-4'>
                        <MDBCol md='6'>
                           <p className='font-weight-medium'>Saldo</p>
                           <h5>$0.00 MXN</h5>
                        </MDBCol>
                     </MDBRow>
                     <MDBRow>
                        <MDBCol md='6'>
                           <p className='font-weight-medium'>Por cobrar a Kargo</p>
                           <h5>$0.00 MXN</h5>
                        </MDBCol>
                        <MDBCol md='6'>
                           <p className='font-weight-medium'>Deuda a Kargo</p>
                           <h5>$0.00 MXN</h5>
                        </MDBCol>
                     </MDBRow>
                  </MDBCardBody>
               </MDBCard>
            </MDBCol>
            <MDBCol md='6'>
               <MDBCard>
                  <div className='card-header card-header-shadow bg-dark-orange text-white'>
                     <MDBIcon size='lg' icon='money-bill-wave' className='mr-3' />
                     Pagar a choferes
                  </div>
                  <MDBCardBody>
                     <MDBRow className='mb-4'>
                        <MDBCol md='6'>
                           <p className='font-weight-medium'>Monto a pagar</p>
                           <h5>$0.00 MXN</h5>
                        </MDBCol>
                     </MDBRow>
                     <MDBRow>
                        <MDBCol md='6'>
                           <p className='font-weight-medium'>Fecha de pago</p>
                           {/* <h5>11 de marzo del 2019</h5> */}
                           <h5>-----------</h5>
                        </MDBCol>
                        <MDBCol className='text-center justify-content-center align-self-center' md='6'>
                           <MDBBtn color='info' className='text-capitalize btn-top-action' onClick={() => { }}>
                              <MDBIcon className='mr-2' icon='download' size='lg' />
                              Descargar Excel
                           </MDBBtn>
                        </MDBCol>
                     </MDBRow>
                  </MDBCardBody>
               </MDBCard>
            </MDBCol>
         </MDBRow>

         <MDBCard className='mb-4'>
            <MDBCardBody className='p-3'>
               <h4 className='mb-4'>Cobranza</h4>
               <MDBRow className='mb-3'>
                  <MDBCol md='4'>
                     <h5>
                        <span className='mr-3'>Efectivo:</span>$5000 MXN <span className='text-success ml-2'>+ 20%</span>
                     </h5>
                  </MDBCol>
                  <MDBCol md='4'>
                     <h5>
                        <span className='mr-3'>Tarjeta:</span>$5000 MXN <span className='text-danger ml-2'>- 5%</span>
                     </h5>
                  </MDBCol>
               </MDBRow>
               <Tabs className='mb-5' onChange={handleTabChange} value={tabValue}>
                  <Tab
                     classes={{
                        wrapper: 'text-capitalize font-size-18'
                     }}
                     label='Efectivo'
                  />
                  <Tab
                     classes={{
                        wrapper: 'text-capitalize font-size-18'
                     }}
                     label='Tarjeta'
                  />
               </Tabs>
               {tabValue === 0 && (
                  <div className='bar-chart' style={{ height: '22rem' }}>
                     <Bar data={barChart} options={barChartOptions} />
                  </div>
               )}
               {tabValue === 1 && (
                  <div className='bar-chart' style={{ height: '22rem' }}>
                     <Bar data={barChart} options={barChartOptions} />
                  </div>
               )}
            </MDBCardBody>
         </MDBCard>
      </Fragment>
   );
};

export default SaldosStats;
