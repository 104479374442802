import React, { useState, useEffect, useCallback, Fragment } from "react";
import { MDBRow, MDBCol, MDBIcon, MDBInput, MDBBtn } from "mdbreact";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
// Mis Componentes
import ToastMessage from "components/shared/ToastMessage";
import SelectInput from "components/shared/SelectInput";
import Tooltip from "components/shared/Tooltip";
import http from "services/http.service";
import useDebounce from "hooks/useDebounce";
import { mapOptionsToViewModel } from "utils";
// Mis Types
import { Filters, Pagination } from "typings/Tablas";
import { Option } from "typings/General";
import SaldosTabla from "./SaldosTabla";

export interface SaldosListaProps {}

const SaldosLista: React.FC<SaldosListaProps> = () => {
  const [choferes, setChoferes] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search.trim(), 500);
  const [zonasOptions, setZonasOptions] = useState<Option[]>([]);
  const [choferesLiderOptions, setChoferesLiderOptions] = useState<Option[]>([]);
  const [tipoChoferOptions, setTipoChoferOptions] = useState<Option[]>([]);
  const [filters, setFilters] = useState<Filters>({
    zona: null,
    choferLider: null,
    tipoChofer: null,
  });
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    totalSize: 10,
    sizePerPage: 10,
  });

  useEffect(() => {
    const fetchFiltros = async () => {
      try {
        const zonaParams = {
          activo: true,
        };
        const { rows: zonas }: any = await http.get("zonas", { params: zonaParams });
        setZonasOptions(mapOptionsToViewModel(zonas));

        // TODO: se mostraran los choferLider inactivos?
        const choferesLiderParams = {
          hasGroup: true,
          limit: 9999,
          page: 1,
        };
        const { rows: choferesLider }: any = await http.get("grupos/choferesLider", { params: choferesLiderParams });
        setChoferesLiderOptions(mapChoferesLiderOptionsToViewModel(choferesLider));

        const { rows: tiposChofer }: any = await http.get("catalogos/TiposChofer");
        setTipoChoferOptions(mapOptionsToViewModel(tiposChofer));
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ocurrió un error al cargar los filtros, recargue la página.</ToastMessage>);
      }
    };

    fetchFiltros();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchChoferes();
    // eslint-disable-next-line
  }, [debouncedSearch, pagination.page, filters.zona, filters.choferLider, filters.tipoChofer]);

  const fetchChoferes = async () => {
    const { zona, choferLider, tipoChofer } = filters;
    const { page, sizePerPage: limit } = pagination;
    try {
      setIsTableLoading(true);

      const params = {
        ...(debouncedSearch && { search: debouncedSearch }),
        ...(zona ? { idZona: zona } : {}),
        ...(choferLider ? { idChoferLider: choferLider } : {}),
        ...(tipoChofer ? { idTipoChofer: tipoChofer } : {}),
        limit,
        page,
      };
      const { rows: choferesList, count: totalSize }: any = await http.get("choferes", { params });
      setChoferes(mapChoferesToViewModel(choferesList));
      setPagination({ ...pagination, totalSize });

      setIsTableLoading(false);
    } catch (error) {
      setIsTableLoading(false);
      toast.error(<ToastMessage type={"error"}>Ocurrió un error al cargar la lista de choferes.</ToastMessage>);
    }
  };

  const mapChoferesLiderOptionsToViewModel = (options: any[]): Option[] => {
    return options.map(option => {
      return {
        value: option[Object.keys(option)[0]],
        label: `${option.nombres} ${option.primerApellido} ${option.segundoApellido}`,
      };
    });
  };

  const mapChoferesToViewModel = (choferes: any[]) => {
    return choferes.map(chofer => {
      return {
        idChofer: chofer.idChofer,
        clave: chofer.clave.length ? chofer.clave[0].clave : "N/A",
        nombreCompleto: `${chofer.nombres} ${chofer.primerApellido} ${chofer.segundoApellido}`,
        email: chofer.email,
        activo: chofer.activo,
        options: { activo: chofer.activo },
      };
    });
  };

  const handleSearchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(input.value);
  };

  const handleRefresh = () => {
    fetchChoferes();
  };

  const handleCleanFilters = () => {
    setSearch("");
    setFilters({
      zona: null,
      choferLider: null,
      tipoChofer: null,
    });
  };

  const handleChangeSelect =
    inputName =>
    (option, { action }) => {
      setFilters({
        ...filters,
        [inputName]: option.value,
      });
    };

  const handlePagar = useCallback(
    async (id: number, index: number) => {
      try {
        const result = await Swal.fire({
          title: `¿Seguro que deseas realizar el pago al chofer?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-success waves-effect waves-light text-capitalize",
            cancelButton: "btn btn-danger waves-effect waves-light text-capitalize ml-2",
          },
          buttonsStyling: false,
        });
        if (result.value) {
          await http.put(`choferes/${id}/pagar`);
          let choferesAux = [...choferes];

          // Actualizar UI
          choferesAux[index].activo = !choferesAux[index].activo;
          setChoferes(choferesAux);
        }
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al realizar el pago, intente de nuevo.</ToastMessage>);
      }
    },
    [choferes]
  );

  const handleTableChange = useCallback(
    (type, { page, sizePerPage }) => {
      setPagination({
        ...pagination,
        page,
      });
    },
    [pagination]
  );

  return (
    <Fragment>
      <h5 className="mb-0">
        <span className="mr-3">Balance de la semana:</span>$5000 MXN
      </h5>
      <div className="table-filters py-2">
        <MDBRow className="">
          <MDBCol md="9">
            <MDBInput
              className="m-0"
              label="Buscar por nombre, clave, correo electrónico"
              outline
              icon="search"
              iconSize="lg"
              onChange={handleSearchChange}
              value={search}
            />
          </MDBCol>
          <MDBCol md="3">
            <div style={{ marginTop: "0.6rem" }}>
              <Tooltip title="Actualizar" placement="top">
                <MDBBtn size="sm" color="danger" onClick={handleRefresh}>
                  <MDBIcon size="2x" icon="sync" fixed />
                </MDBBtn>
              </Tooltip>
              <Tooltip title="Limpiar Filtros" placement="top">
                <MDBBtn size="sm" color="danger" onClick={handleCleanFilters}>
                  <MDBIcon size="2x" icon="eraser" fixed />
                </MDBBtn>
              </Tooltip>
            </div>
          </MDBCol>
        </MDBRow>

        {/* FILTROS */}
        <MDBRow className="mb-3 mt-0 mx-0">
          <MDBCol className="pl-0" md="3">
            <SelectInput
              name="zona"
              placeholder="Zona"
              options={zonasOptions}
              handleCustomSelect={handleChangeSelect}
              value={filters.zona}
            />
          </MDBCol>
          <MDBCol className="pl-0" md="3">
            <SelectInput
              name="choferLider"
              placeholder="Lider"
              options={choferesLiderOptions}
              handleCustomSelect={handleChangeSelect}
              value={filters.choferLider}
            />
          </MDBCol>
          <MDBCol className="pl-0" md="3">
            <SelectInput
              name="tipoChofer"
              placeholder="Tipo"
              options={tipoChoferOptions}
              isSearchable={false}
              handleCustomSelect={handleChangeSelect}
              value={filters.tipoChofer}
            />
          </MDBCol>
        </MDBRow>
      </div>

      <SaldosTabla
        choferes={choferes}
        isTableLoading={isTableLoading}
        pagination={pagination}
        handleTableChange={handleTableChange}
        handlePagar={handlePagar}
      />
    </Fragment>
  );
};

export default SaldosLista;

