import React from "react";
import { MDBListGroup, MDBListGroupItem, MDBIcon } from "mdbreact";
import { NavLink } from "react-router-dom";
import "./Sidebar.css";
import Can from "components/shared/Can";
import kargoMX_logo from "../../../assets/images/KargoMX-LogoV1.png";

export interface SidebarProps {
  toggled: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ toggled }) => {
  return (
    <div className={["sidebar", toggled ? "collapsed" : ""].join(" ")}>
      <header className="bg-dark text-center" style={{ height: "64px" }}>
        <img alt="Kargo Logo" className="img-fluid" style={{ height: "64px" }} src={toggled ? kargoMX_logo : kargoMX_logo} />
      </header>
      <MDBListGroup className="sidebar-links list-group-flush">
        <Can I="access" a="seccion_monitor">
          <NavLink className="mt-3 mb-2" to="/r/monitor" activeClassName="active">
            <MDBListGroupItem>
              <MDBIcon icon="desktop" className="mr-3" fixed />
              <span>Monitor</span>
            </MDBListGroupItem>
          </NavLink>
        </Can>
        <Can I="access" a="seccion_choferes">
          <NavLink className="mb-2" to="/r/choferes" activeClassName="active">
            <MDBListGroupItem>
              <MDBIcon icon="taxi" className="mr-3" fixed />
              <span>Choferes</span>
            </MDBListGroupItem>
          </NavLink>
        </Can>
        <Can I="access" a="seccion_grupos">
          <NavLink className="mb-2" to="/r/grupos" activeClassName="active">
            <MDBListGroupItem>
              <MDBIcon icon="users" className="mr-3" fixed />
              <span>Grupos</span>
            </MDBListGroupItem>
          </NavLink>
        </Can>
        <Can I="access" a="seccion_viajes">
          <NavLink className="mb-2" to="/r/viajes" activeClassName="active">
            <MDBListGroupItem>
              <MDBIcon icon="route" className="mr-3" fixed />
              <span>Viajes</span>
            </MDBListGroupItem>
          </NavLink>
        </Can>
        <Can I="access" a="seccion_clientes">
          <NavLink className="mb-2" to="/r/clientes" activeClassName="active">
            <MDBListGroupItem>
              <MDBIcon icon="address-card" className="mr-3" fixed />
              <span>Clientes</span>
            </MDBListGroupItem>
          </NavLink>
        </Can>
        {/*<Can I='access' a='seccion_quejas'>
               <NavLink className='mb-2' to='/r/quejas' activeClassName='active'>
                  <MDBListGroupItem>
                     <MDBIcon icon='comments' className='mr-3' fixed />
                     <span>Quejas</span>
                  </MDBListGroupItem>
               </NavLink>
            </Can>*/}
        {/* <Can I='access' a='seccion_saldos'>
               <NavLink className='mb-2' to='/r/saldos' activeClassName='active'>
                  <MDBListGroupItem>
                     <MDBIcon icon='money-bill-wave' className='mr-3' fixed />
                     <span>Saldos</span>
                  </MDBListGroupItem>
               </NavLink>
            </Can> */}
        <Can I="access" a="seccion_zonas">
          <NavLink className="mb-2" to="/r/zonas" activeClassName="active">
            <MDBListGroupItem>
              <MDBIcon icon="map-pin" className="mr-3" fixed />
              <span>Zonas de operación</span>
            </MDBListGroupItem>
          </NavLink>
        </Can>
        <Can I="access" a="seccion_usuarios">
          <NavLink className="mb-2" to="/r/usuarios" activeClassName="active">
            <MDBListGroupItem>
              <MDBIcon icon="user-cog" className="mr-3" fixed />
              <span>Usuarios</span>
            </MDBListGroupItem>
          </NavLink>
        </Can>
        <Can I="access" a="seccion_reportes">
          <NavLink className="mb-2" to="/r/reportes" activeClassName="active">
            <MDBListGroupItem>
              <MDBIcon icon="file-alt" className="mr-3" fixed />
              <span>Reportes</span>
            </MDBListGroupItem>
          </NavLink>
        </Can>
        {/* <Can I='access' a='seccion_registros'>
               <NavLink className='mb-2' to='/r/registros' activeClassName='active'>
                  <MDBListGroupItem>
                     <MDBIcon icon='bell' className='mr-3' fixed />
                     <span>Registros</span>
                  </MDBListGroupItem>
               </NavLink>
            </Can> */}
        <Can I="access" a="seccion_config">
          <NavLink to="/r/configuracion" activeClassName="active">
            <MDBListGroupItem>
              <MDBIcon icon="cogs" className="mr-3" fixed />
              <span>Configuración</span>
            </MDBListGroupItem>
          </NavLink>
        </Can>
      </MDBListGroup>
    </div>
  );
};

export default Sidebar;

