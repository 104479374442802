import React, { useState, useEffect, useCallback, Fragment } from "react";
import { MDBRow, MDBCol, MDBIcon, MDBInput, MDBBtn } from "mdbreact";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
// Mis Componentes
import ChoferesTabla from "./ChoferesTabla";
import ToastMessage from "components/shared/ToastMessage";
import SelectInput from "components/shared/SelectInput";
import Tooltip from "components/shared/Tooltip";
import http from "services/http.service";
import useDebounce from "hooks/useDebounce";
// Mis Types
import { Filters, Pagination } from "typings/Tablas";
import { Option } from "typings/General";
import { mapOptionsToViewModel } from "utils";

export interface ChoferesListaProps {}

const ChoferesLista: React.FC<ChoferesListaProps> = () => {
  const [choferes, setChoferes] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search.trim(), 500);
  const [zonasOptions, setZonasOptions] = useState<Option[]>([]);
  const [choferesLideresOptions, setChoferesLideresOptions] = useState<Option[]>([]);
  const [tipoChoferOptions, setTipoChoferOptions] = useState<Option[]>([]);
  const [filters, setFilters] = useState<Filters>({
    zona: null,
    choferLider: null,
    tipoChofer: null,
  });
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    totalSize: 10,
    sizePerPage: 10,
  });

  // Obtener los datos de los filtros
  useEffect(() => {
    const fetchFiltros = async () => {
      try {
        const zonaParams = {
          activo: true,
        };
        const { rows: zonas }: any = await http.get("zonas", { params: zonaParams });
        setZonasOptions(mapOptionsToViewModel(zonas));

        // TODO: se mostraran los choferesLider inactivos?
        const choferesLiderParams = {
          hasGroup: true,
          limit: 9999,
          page: 1,
        };
        const { rows: choferesLider }: any = await http.get("grupos/choferesLider", { params: choferesLiderParams });
        setChoferesLideresOptions(mapChoferLiderOptionsToViewModel(choferesLider));

        const { rows: tiposChofer }: any = await http.get("catalogos/TiposChofer");
        setTipoChoferOptions(mapOptionsToViewModel(tiposChofer));
      } catch (error) {
        if ((error.status && error.status !== 500) || error.type) {
          toast.error(<ToastMessage type={"error"}>Ocurrió un error al cargar los filtros, recargue la página.</ToastMessage>);
        }
      }
    };

    fetchFiltros();
    // eslint-disable-next-line
  }, []);

  // Obtener la lista de grupos
  useEffect(() => {
    fetchChoferes();
    // eslint-disable-next-line
  }, [debouncedSearch, pagination.page, filters.zona, filters.choferLider, filters.tipoChofer]);

  const fetchChoferes = useCallback(async () => {
    const { zona, choferLider, tipoChofer } = filters;
    const { page, sizePerPage: limit } = pagination;
    try {
      setIsTableLoading(true);

      const params = {
        ...(debouncedSearch && { search: debouncedSearch }),
        ...(zona ? { idZona: zona } : {}),
        ...(choferLider ? { idChoferLider: choferLider } : {}),
        ...(tipoChofer ? { idTipoChofer: tipoChofer } : {}),
        limit,
        page,
      };
      const { rows: choferesList, count: totalSize }: any = await http.get("choferes", { params });
      setChoferes(mapChoferesToViewModel(choferesList));
      setPagination({ ...pagination, totalSize });

      setIsTableLoading(false);
    } catch (error) {
      setIsTableLoading(false);
      if ((error.status && error.status !== 500) || error.type) {
        toast.error(<ToastMessage type={"error"}>Ocurrió un error al cargar la lista de choferes.</ToastMessage>);
      }
    }
  }, [debouncedSearch, filters, pagination]);

  const mapChoferLiderOptionsToViewModel = (options: any[]): Option[] => {
    return options.map(option => {
      return {
        value: option[Object.keys(option)[0]],
        label: `${option.nombres} ${option.primerApellido} ${option.segundoApellido}`,
      };
    });
  };

  const mapChoferesToViewModel = (choferes: any[]) => {
    return choferes.map(chofer => {
      return {
        idChofer: chofer.idChofer,
        clave: chofer.clave.length ? chofer.clave[0].clave : "-",
        nombreCompleto: `${chofer.nombres} ${chofer.primerApellido} ${chofer.segundoApellido}`,
        email: chofer.email,
        activo: chofer.activo,
        tipoChofer: chofer.tipoChofer.nombre,
        options: { castigado: chofer.castigado },
        zona: chofer.zona,
      };
    });
  };

  const handleSearchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(input.value);
  };

  const handleRefresh = () => {
    fetchChoferes();
  };

  const handleCleanFilters = () => {
    setSearch("");
    setFilters({
      zona: null,
      choferLider: null,
      tipoChofer: null,
    });
  };

  const handleChangeSelect =
    inputName =>
    (option, { action }) => {
      setFilters({
        ...filters,
        [inputName]: option.value,
      });
    };

  const handleCastigarChofer = useCallback(
    async (id: number, castigado: boolean, index: number) => {
      try {
        const result = await Swal.fire({
          title: `¿Estas seguro que deseas ${castigado ? "perdonar" : "castigar"} al chofer?`,
          text: "Puedes revertir este cambio en cualquier momento presionando el mismo boton",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-success waves-effect waves-light text-capitalize",
            cancelButton: "btn btn-danger waves-effect waves-light text-capitalize ml-2",
          },
          buttonsStyling: false,
        });
        if (result.value) {
          await http.put(`choferes/${id}/${castigado ? "indultar" : "castigar"}`);
          let choferesAux = [...choferes];
          // Actualizar UI
          choferesAux[index].options.castigado = !choferesAux[index].options.castigado;
          setChoferes(choferesAux);
        }
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ha ocurrido un error, intente de nuevo.</ToastMessage>);
      }
    },
    [choferes]
  );

  const handleToggleEstado = useCallback(
    async (id: number, activo: boolean, index: number, prefijo: string) => {
      try {
        const result = await Swal.fire({
          title: `¿Estás seguro que deseas ${activo ? "desactivar" : "activar"} al chofer?`,
          text: "Puedes revertir este cambio en cualquier momento presionando el mismo boton",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-success waves-effect waves-light text-capitalize",
            cancelButton: "btn btn-danger waves-effect waves-light text-capitalize ml-2",
          },
          buttonsStyling: false,
          ...(!activo && {
            html: `<label style="float:left">Prefijo: <strong>${prefijo}</strong></label>`,
            input: "text",
            inputPlaceholder: "Nueva clave de chofer",
          }),
        });
        if (result.value) {
          await http.put(`choferes/${id}/${activo ? "desactivar" : "activar"}`, {
            prefijoClave: prefijo,
            codigoClave: result.value,
          });
          // Actualizar UI
          // let choferesAux = [...choferes];
          // choferesAux[index].activo = !choferesAux[index].activo;
          // setChoferes(choferesAux);
          fetchChoferes();
        }
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>{error.message || "Ha ocurrido un error, intente de nuevo."}</ToastMessage>);
      }
    },
    [fetchChoferes]
  );

  const handleTableChange = useCallback(
    (type, { page, sizePerPage }) => {
      setPagination({
        ...pagination,
        page,
      });
    },
    [pagination]
  );

  return (
    <Fragment>
      <div className="table-filters py-2">
        <MDBRow className="">
          <MDBCol md="9">
            <MDBInput
              className="m-0"
              label="Buscar por nombre, clave, correo electrónico"
              outline
              icon="search"
              iconSize="lg"
              onChange={handleSearchChange}
              value={search}
            />
          </MDBCol>
          <MDBCol md="3">
            <div style={{ marginTop: "0.6rem" }}>
              <Tooltip title="Actualizar" placement="top">
                <MDBBtn size="sm" color="danger" onClick={handleRefresh}>
                  <MDBIcon size="2x" icon="sync" fixed />
                </MDBBtn>
              </Tooltip>
              <Tooltip title="Limpiar Filtros" placement="top">
                <MDBBtn size="sm" color="danger" onClick={handleCleanFilters}>
                  <MDBIcon size="2x" icon="eraser" fixed />
                </MDBBtn>
              </Tooltip>
            </div>
          </MDBCol>
        </MDBRow>

        {/* FILTROS */}
        <MDBRow className="mb-3 mt-0 mx-0">
          <MDBCol className="pl-0" md="3">
            <SelectInput
              name="zona"
              placeholder="Zona"
              options={zonasOptions}
              handleCustomSelect={handleChangeSelect}
              value={filters.zona}
            />
          </MDBCol>
          <MDBCol className="pl-0" md="3">
            <SelectInput
              name="choferLider"
              placeholder="Lider"
              options={choferesLideresOptions}
              handleCustomSelect={handleChangeSelect}
              value={filters.choferLider}
            />
          </MDBCol>
          <MDBCol className="pl-0" md="3">
            <SelectInput
              name="tipoChofer"
              placeholder="Tipo"
              options={tipoChoferOptions}
              isSearchable={false}
              handleCustomSelect={handleChangeSelect}
              value={filters.tipoChofer}
            />
          </MDBCol>
        </MDBRow>
      </div>

      <ChoferesTabla
        choferes={choferes}
        isTableLoading={isTableLoading}
        pagination={pagination}
        handleTableChange={handleTableChange}
        handleCastigarChofer={handleCastigarChofer}
        handleToggleEstado={handleToggleEstado}
      />
    </Fragment>
  );
};

export default ChoferesLista;

