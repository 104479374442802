import React, { Fragment, forwardRef } from "react";
import { MDBRow, MDBCol, MDBBtn, MDBIcon } from "mdbreact";
import { Map, TileLayer, Polygon } from "react-leaflet";
// Mis Types
import { Option } from "typings/General";
import Zona from "models/Zonas";

const getTitle = (id: number) => {
  switch (id) {
    case 1:
      return "Tarifas para efectivo";
    case 2:
      return "Tarifas para tarjeta";
    case 3:
      return "Tarifas para monedero";
  }
};

export interface ZonaResumenProps {
  zona: Zona;
  map: any;
  administradoresZona: Option[];
  tipoVehiculos: any[];
  onBackBtnClick: () => void;
  onFormSubmit: () => void;
  isSubmitting: boolean;
}

const ZonaResumen: React.FC<ZonaResumenProps> = (
  { zona, map, administradoresZona, tipoVehiculos, onBackBtnClick, onFormSubmit, isSubmitting },
  ref
) => {
  const getUsuarioName = (id: number) => {
    const usuario: Option = administradoresZona.find(usuario => usuario.value === id) as Option;
    return usuario.label;
  };

  const getTipoVehiculoName = (id: number) => {
    const tipoVehiculo: Option = tipoVehiculos.find(tipoVehiculo => tipoVehiculo.value === id) as Option;
    return tipoVehiculo.label;
  };

  return (
    <Fragment>
      <p className="text-muted font-size-18">Información de zona</p>

      <MDBRow className="mb-4">
        <MDBCol md="3">
          <label className="">Nombre de Zona</label>
          <p className="font-weight-medium">{zona.nombreZona}</p>
        </MDBCol>
        <MDBCol md="3">
          <label className="">Administrador de Zona</label>
          <p className="font-weight-medium">{zona.idUsuarioAdmin ? getUsuarioName(zona.idUsuarioAdmin) : "N/A"}</p>
        </MDBCol>
      </MDBRow>

      <hr />
      <p className="text-muted font-size-18">Información de zona</p>

      {/* MAPA */}
      <Map ref={ref}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Polygon positions={map.polygonPoints} />
      </Map>

      <p className="text-muted mt-4 font-size-18">Métodos de pago</p>
      <MDBRow className="mb-4">
        <MDBCol md="3">
          <label className="">Pago con efectivo</label>
          <p className="font-weight-medium">{zona.pagoEfectivo ? "Sí" : "No"}</p>
        </MDBCol>
        <MDBCol md="3">
          <label className="">Pago con tarjeta</label>
          <p className="font-weight-medium">{zona.pagoTarjeta ? "Sí" : "No"}</p>
        </MDBCol>
        <MDBCol md="3">
          <label className="">Pago con monedero</label>
          <p className="font-weight-medium">Sí</p>
          {/* <p className='font-weight-medium'>{zona.pagoMonedero ? 'Sí' : 'No'}</p> */}
        </MDBCol>
      </MDBRow>

      <hr />

      {/* RENDER TARIFAS */}
      {zona.tarifas.map((tarifa, index) => (
        <Fragment key={(tarifa.idTarifa as number) ? (tarifa.idTarifa as number) : tarifa.uid}>
          <p className="text-muted font-size-18">
            {`${getTitle(tarifa.idMetodoPago as number)} - ${getTipoVehiculoName(tarifa.idTipoVehiculo as number)}`}
          </p>
          <MDBRow className="mb-4">
            <MDBCol md="3">
              <label className="">Tarifa única</label>
              <p className="font-weight-medium">{tarifa.tarifaUnica ? `$${tarifa.tarifaUnica}` : "N/A"}</p>
            </MDBCol>
            <MDBCol md="3">
              <label className="">Costo por kilómetro</label>
              <p className="font-weight-medium">{tarifa.costoKm ? `$${tarifa.costoKm}` : "N/A"}</p>
            </MDBCol>
            <MDBCol md="3">
              <label className="">Costo por minuto</label>
              <p className="font-weight-medium">{tarifa.costoMin ? `$${tarifa.costoMin}` : "N/A"}</p>
            </MDBCol>
            <MDBCol md="3">
              <label className="">Banderazo</label>
              <p className="font-weight-medium">{tarifa.banderazo ? `$${tarifa.banderazo}` : "N/A"}</p>
            </MDBCol>
          </MDBRow>

          <MDBRow className="mb-4">
            <MDBCol md="3">
              <label className="">Tarifa mínima</label>
              <p className="font-weight-medium">{tarifa.tarifaMinima ? `$${tarifa.tarifaMinima}` : "N/A"}</p>
            </MDBCol>
            <MDBCol md="3">
              <label className="">Multiplicador</label>
              <p className="font-weight-medium">{tarifa.multiplicador ? `x${tarifa.multiplicador}` : "N/A"}</p>
            </MDBCol>
          </MDBRow>

          <hr />
        </Fragment>
      ))}

      <MDBRow className="mt-5 justify-content-center">
        <MDBCol md="4">
          <MDBBtn outline className="font-weight-medium mx-0 px-5 text-capitalize" block onClick={onBackBtnClick}>
            Atrás
          </MDBBtn>
        </MDBCol>
        <MDBCol md="4">
          <MDBBtn color="default" className="font-weight-medium mx-0 px-5" block onClick={onFormSubmit} disabled={isSubmitting}>
            {isSubmitting ? <MDBIcon icon="spinner" pulse size="lg" /> : "Terminar"}
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </Fragment>
  );
};

export default forwardRef(ZonaResumen);

