import React from "react";
import { Switch, Route } from "react-router-dom";
import MonitorPage from "components/pages/MonitorPage";
import ChoferStats from "components/choferes/ChoferStats";
import ChoferDetalles from "components/choferes/ChoferDetalles";
import ChoferStepperContainer from "components/choferes/form/ChoferStepperContainer";
import ChoferesPage from "components/pages/ChoferesPage";
import GrupoDetalles from "components/grupos/GrupoDetalles";
import GrupoStepperContainer from "components/grupos/GrupoStepperContainer";
import GruposPage from "components/pages/GruposPage";
import ViajeDetalles from "components/viajes/ViajeDetalles";
import ViajesStats from "components/viajes/ViajesStats";
import ViajesPage from "components/pages/ViajesPage";
import ClienteDetalles from "components/clientes/cliente-detalles/ClienteDetalles";
import ClienteStats from "components/clientes/ClienteStats";
import ClientesPage from "components/pages/ClientesPage";
import QuejaDetalles from "components/quejas/QuejaDetalles";
import QuejasPage from "components/pages/QuejasPage";
import SaldosPage from "components/pages/SaldosPage";
import ZonaDetalles from "components/zonas/ZonaDetalles";
import ZonaStepper from "components/zonas/ZonaStepper";
import ZonasPage from "components/pages/ZonasPage";
import RegistrosPage from "components/pages/RegistrosPage";
import UsuariosPage from "components/pages/UsuariosPage";
import FormUsuario from "components/usuarios/form/FormUsuario";
import UsuarioDetalles from "components/usuarios/UsuarioDetalles";
import ReportesPage from "components/pages/ReportesPage";
import ConfigVariablesPage from "components/pages/ConfigVariablesPage";
// import Can from 'components/shared/Can';

const DashboardRoutes: React.FC = () => {
  return (
    <Switch>
      {/* <Can I='access' a='seccion_monitor'> */}
      <Route path={`/r/monitor`} component={MonitorPage} />
      {/* </Can> */}

      {/* <Can I='access' a='seccion_choferes'> */}
      <Route path={`/r/choferes/:id/estadisticas`} component={ChoferStats} />
      <Route path={`/r/choferes/:id/detalles`} component={ChoferDetalles} />
      <Route path={`/r/choferes/:id/editar`} component={ChoferStepperContainer} />
      <Route path={`/r/choferes/nuevo`} component={ChoferStepperContainer} />
      <Route path={`/r/choferes`} component={ChoferesPage} />
      {/* </Can> */}

      {/* <Can I='access' a='seccion_grupos'> */}
      <Route path={`/r/grupos/:id/detalles`} component={GrupoDetalles} />
      <Route path={`/r/grupos/:id/editar`} component={GrupoStepperContainer} />
      <Route path={`/r/grupos/nuevo`} component={GrupoStepperContainer} />
      <Route path={`/r/grupos`} component={GruposPage} />
      {/* </Can> */}

      {/* <Can I='access' a='seccion_viajes'> */}
      <Route path={`/r/viajes/estadisticas`} component={ViajesStats} />
      <Route path={`/r/viajes/:id/detalles`} component={ViajeDetalles} />
      <Route path={`/r/viajes`} component={ViajesPage} />
      {/* </Can> */}

      {/* <Can I='access' a='seccion_clientes'> */}
      <Route path={`/r/clientes/:id/estadisticas`} component={ClienteStats} />
      <Route path={`/r/clientes/:id/detalles`} component={ClienteDetalles} />
      <Route path={`/r/clientes`} component={ClientesPage} />
      {/* </Can> */}

      {/* <Can I='access' a='seccion_quejas'> */}
      <Route path={`/r/quejas/:id/detalles`} component={QuejaDetalles} />
      <Route path={`/r/quejas`} component={QuejasPage} />
      {/* </Can> */}

      {/* <Can I='access' a='seccion_saldos'> */}
      <Route path={`/r/saldos`} component={SaldosPage} />
      {/* </Can> */}

      {/* <Can I='access' a='seccion_zonas'> */}
      <Route path={`/r/zonas/:id/detalles`} component={ZonaDetalles} />
      <Route path={`/r/zonas/:id/editar`} component={ZonaStepper} />
      <Route path={`/r/zonas/nueva`} component={ZonaStepper} />
      <Route path={`/r/zonas`} component={ZonasPage} />
      {/* </Can> */}

      {/* <Can I='access' a='seccion_reportes'> */}
      <Route path={`/r/registros`} component={RegistrosPage} />
      {/* </Can> */}

      {/* <Can I='access' a='seccion_usuarios'> */}
      <Route path={`/r/usuarios/:id/detalles`} component={UsuarioDetalles} />
      <Route path={`/r/usuarios/:id/editar`} component={FormUsuario} />
      <Route path={`/r/usuarios/nuevo`} component={FormUsuario} />
      <Route path={`/r/usuarios`} component={UsuariosPage} />
      {/* </Can> */}

      {/* <Can I='access' a='seccion_reportes'> */}
      <Route path={`/r/reportes`} component={ReportesPage} />
      {/* </Can> */}

      <Route path={`/r/configuracion`} component={ConfigVariablesPage} />
    </Switch>
  );
};

export default DashboardRoutes;

