import React, { useState, useEffect, Fragment, useCallback, useMemo } from "react";
import { MDBRow, MDBCol, MDBIcon, MDBCardBody, MDBBadge, MDBInput, MDBBtn } from "mdbreact";
import moment from "moment";
import { toast } from "react-toastify";
// Mis Componentes
import ToastMessage from "components/shared/ToastMessage";
import SelectInput from "components/shared/SelectInput";
import Tooltip from "components/shared/Tooltip";
import http from "services/http.service";
import useDebounce from "hooks/useDebounce";
import useInterval from "hooks/useInterval";
import { mapOptionsToViewModel } from "utils";
// Mis Types
import { Filters, Pagination } from "typings/Tablas";
import { Option } from "typings/General";
import MonitorSolicitudesTabla from "./MonitorSolicitudesTabla";
import AssignChoferModal from "./AssignChoferModal";

export interface TablaSolicitudesProps {
  idZona: number;
  onVerRuta: (viajeId: number | null, solicitudId: number | null) => Promise<void>;
}

const TablaSolicitudes: React.FC<TablaSolicitudesProps> = ({ idZona, onVerRuta }) => {
  const [solicitudes, setSolicitudes] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search.trim(), 500);
  const [estatusOptions, setEstatusOptions] = useState<Option[]>([]);
  const [filters, setFilters] = useState<Filters>({
    estatus: null,
  });
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    totalSize: 10,
    sizePerPage: 10,
  });
  const [showAssignChoferModal, setShowAssignChoferModal] = useState<any>(0);

  const actionsFormatter = useCallback(
    (cell, row) => {
      const asignable = [1, 4, 5, 6].includes(row.estatus.idEstadoPeticion);
      return (
        <Fragment>
          <Tooltip title="Ver ruta" placement="top">
            <MDBBtn color={"mdb-color"} className="btn btn-sm my-0 mr-2 btn-action" onClick={() => onVerRuta(null, row.idPeticion)}>
              <MDBIcon size="2x" icon="route" />
            </MDBBtn>
          </Tooltip>
          <Tooltip title="Asignar viaje" placement="top">
            <MDBBtn
              color={"mdb-color"}
              className="btn btn-sm my-0 mr-2 btn-action"
              disabled={!asignable}
              onClick={() => {
                setShowAssignChoferModal(row.idPeticion);
              }}>
              <MDBIcon size="2x" icon="clipboard-check" />
            </MDBBtn>
          </Tooltip>
        </Fragment>
      );
    },
    [onVerRuta]
  );

  const estatusBadgeFormatter = useCallback((cell, row) => {
    switch (row.estatus.idEstadoPeticion) {
      case 1:
        return <MDBBadge color="default">{row.estatus.nombre}</MDBBadge>;
      case 2:
        return <MDBBadge color="success">{row.estatus.nombre}</MDBBadge>;
      case 3:
        return <MDBBadge color="light">{row.estatus.nombre}</MDBBadge>;
      case 4:
        return <MDBBadge color="danger">{row.estatus.nombre}</MDBBadge>;
      case 5:
        return <MDBBadge color="warning">{row.estatus.nombre}</MDBBadge>;
      case 6:
        return <MDBBadge color="danger">{row.estatus.nombre}</MDBBadge>;
      case 7:
        return <MDBBadge color="danger">{row.estatus.nombre}</MDBBadge>;
    }
  }, []);

  const columns = useMemo(() => {
    return [
      {
        dataField: "idPeticion",
        text: "ID",
        headerAlign: "center",
        align: "center",
        headerClasses: "font-weight-bold text-dark",
      },
      {
        dataField: "estatus",
        text: "Estatus",
        headerAlign: "center",
        align: "center",
        headerClasses: "font-weight-bold text-dark",
        formatter: estatusBadgeFormatter,
      },
      {
        dataField: "nombreCliente",
        text: "Cliente",
        headerAlign: "center",
        align: "center",
        headerClasses: "font-weight-bold text-dark",
      },
      {
        dataField: "telefonoCliente",
        text: "Teléfono Cliente",
        headerAlign: "center",
        align: "center",
        headerClasses: "font-weight-bold text-dark",
      },
      {
        dataField: "horaSolicitud",
        text: "Hora de solicitud",
        headerAlign: "center",
        align: "center",
        headerClasses: "font-weight-bold text-dark",
      },
      {
        dataField: "opciones",
        text: "Opciones",
        isDummyField: true,
        headerAlign: "center",
        align: "center",
        headerClasses: "font-weight-bold text-dark",
        formatter: actionsFormatter,
      },
    ];
  }, [estatusBadgeFormatter, actionsFormatter]);

  // Solicitudes
  useEffect(() => {
    fetchSolicitudes();
    // eslint-disable-next-line
  }, [debouncedSearch, pagination.page, filters.estatus, idZona]);

  // Obtener datos de los filtros
  useEffect(() => {
    const fetchFiltro = async () => {
      try {
        const { rows }: any = await http.get("catalogos/EstadoSolicitud");
        setEstatusOptions(mapOptionsToViewModel(rows));
      } catch (error) {}
    };

    fetchFiltro();
  }, []);

  const fetchSolicitudes = async () => {
    const { estatus } = filters;
    const { page, sizePerPage: limit } = pagination;
    try {
      setIsTableLoading(true);

      const params = {
        ...(debouncedSearch && { search: debouncedSearch }),
        ...(idZona && { idZona }),
        ...(estatus ? { idEstadoPeticion: estatus } : {}),
        hoy: true,
        order: "desc",
        limit,
        page,
      };
      const { rows: solicitudesList, count: totalSize }: any = await http.get("peticiones", { params });
      setSolicitudes(mapSolicitudesToViewModel(solicitudesList));
      setPagination({ ...pagination, totalSize });

      setIsTableLoading(false);
    } catch (error) {
      setIsTableLoading(false);
      toast.error(<ToastMessage type={"error"}>Ocurrió un error al cargar la lista de solicitudes, recargue la página.</ToastMessage>);
    }
  };

  const mapSolicitudesToViewModel = (solicitudes: any[]) => {
    return solicitudes.map(solicitud => {
      return {
        idPeticion: solicitud.idPeticion,
        estatus: solicitud.estado,
        nombreCliente: `${solicitud.cliente.nombre}`,
        telefonoCliente: solicitud.cliente.telefono ? `${solicitud.cliente.telefono.substring(2)}` : "N/A",
        horaSolicitud: moment(solicitud.createdAt).format("HH:mm A"),
        polilinea: solicitud.polilinea,
      };
    });
  };

  const handleTableChange = useCallback(
    (type, { page, sizePerPage }) => {
      setPagination({
        ...pagination,
        page,
      });
    },
    [pagination]
  );

  const handleSearchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(input.value);
  };

  const handleRefresh = () => {
    fetchSolicitudes();
  };

  // Refresca la lista cada 30seg
  useInterval(() => {
    handleRefresh();
  }, 30000);

  const handleCleanFilters = () => {
    setSearch("");
    setFilters({
      estatus: null,
    });
  };

  const handleChangeSelect =
    inputName =>
    (option, { action }) => {
      setFilters({
        ...filters,
        [inputName]: option.value.toString(),
      });
    };

  return (
    <Fragment>
      <div className="card-header card-header-shadow bg-dark-orange text-white" style={{ zIndex: 0 }}>
        <MDBIcon size="lg" far icon="envelope" className="mr-3" />
        Solicitudes
      </div>
      <MDBCardBody className="px-3 pb-3 pt-0">
        <div className="table-filters py-2">
          <MDBRow className="">
            <MDBCol md="9">
              <MDBInput
                className="m-0"
                label="Buscar por chofer, cliente, ID"
                outline
                icon="search"
                iconSize="lg"
                onChange={handleSearchChange}
              />
            </MDBCol>
            <MDBCol md="3">
              <div style={{ marginTop: "0.6rem" }}>
                <Tooltip title="Actualizar" placement="top">
                  <MDBBtn size="sm" color="danger" onClick={handleRefresh}>
                    <MDBIcon size="2x" icon="sync" fixed />
                  </MDBBtn>
                </Tooltip>
                <Tooltip title="Limpiar Filtros" placement="top">
                  <MDBBtn size="sm" color="danger" onClick={handleCleanFilters}>
                    <MDBIcon size="2x" icon="eraser" fixed />
                  </MDBBtn>
                </Tooltip>
              </div>
            </MDBCol>
          </MDBRow>

          {/* FILTROS */}
          <MDBRow className="mb-3 mt-0 mx-0">
            <MDBCol className="pl-0" md="3">
              <SelectInput
                name="estatus"
                placeholder="Estatus"
                options={estatusOptions}
                handleCustomSelect={handleChangeSelect}
                value={filters.estatus}
              />
            </MDBCol>
          </MDBRow>
        </div>

        <MonitorSolicitudesTabla
          columns={columns}
          solicitudes={solicitudes}
          isTableLoading={isTableLoading}
          pagination={pagination}
          handleTableChange={handleTableChange}
        />
      </MDBCardBody>
      <AssignChoferModal
        show={!!showAssignChoferModal}
        idPeticion={showAssignChoferModal}
        onAccept={() => {
          setShowAssignChoferModal(0);
          handleRefresh();
        }}
        onCancel={() => {
          setShowAssignChoferModal(0);
        }}
      />
    </Fragment>
  );
};

export default TablaSolicitudes;

