import React, { useEffect, useState } from "react";
import { MDBCard, MDBCardBody, MDBCardImage } from "mdbreact";
import kargoMX_logo from "../../assets/images/KargoMX-Logo.png";
import { RouteComponentProps } from "react-router-dom";
import http from "services/http.service";

export interface RequestPageProps extends RouteComponentProps {}

const RequestPage: React.FC<RequestPageProps> = ({ match }) => {
  const code = match.params["code"];
  const [request, setRequest] = useState<any>();

  useEffect(() => {
    http.get("public/request/" + code).then(res => {
      setRequest(res);
    });
  }, [code]);

  return (
    <section id="auth">
      <MDBCard className="mx-auto my-auto p-0">
        <MDBCardBody className="text-center">
          <MDBCardImage className="img-fluid mx-auto image-size" src={kargoMX_logo} />
          <h1>Solicitud de eliminación de datos</h1>
          <p>
            Las solicitudes de eliminación de datos permiten a los usuarios eliminar información personal que fue obtenida a
            través de los distintos medios de registro.
          </p>
          {request && (
            <p>
              <span>
                <b>Código:</b> {code}
              </span>
              <br />
              <span>
                <b>Tipo de solicitud:</b> {request.type}
              </span>
              <br />
              <span>
                <b>Estado de solicitud:</b> {request.status ? "Completada" : "Pendiente"}
              </span>
            </p>
          )}
          {!request && <p>Solicitud no encontrada.</p>}
        </MDBCardBody>
      </MDBCard>
    </section>
  );
};

export default RequestPage;

