import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import AuthRoutes from 'routes/auth.routes';
import { MDBCard, MDBCardBody, MDBCardImage } from 'mdbreact';
import { getToken } from 'services/authentication.service';
import './AuthPage.css';
import kargoMX_logo from "../../assets/images/KargoMX-Logo.png"

export interface AuthPageProps { }

const AuthPage: React.FC<AuthPageProps> = () => {
   const [toDashboard, setToDashboard] = useState<boolean>(false);
   useEffect(() => {
      const token = getToken();
      if (token) {
         setToDashboard(true);
      }
   }, []);

   if (toDashboard) {
      return <Redirect to='/r/monitor' />;
   }

   return (
      <section id='auth'>
         <MDBCard className='card-login mx-auto my-auto p-0'>
            <MDBCardBody>
               <MDBCardImage className='img-fluid mx-auto image-size' src={kargoMX_logo} />
               <AuthRoutes />
            </MDBCardBody>
         </MDBCard>
      </section>
   );
};

export default AuthPage;
