import React, { useState, useEffect, Fragment } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBIcon, MDBNav, MDBNavItem } from "mdbreact";
import { Bar, Pie } from "react-chartjs-2";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { toast } from "react-toastify";
// Mis Componentes
import ToastMessage from "components/shared/ToastMessage";
import useScrollTop from "hooks/useScrollTop";
import http from "services/http.service";

export interface ClienteStatsProps extends RouteComponentProps {}

const ClienteStats: React.FC<ClienteStatsProps> = ({ match }) => {
  const clienteId = match.params["id"];
  const [cliente, setCliente] = useState<any>();
  const [tabValue, setTabValue] = useState<number>(0);
  const [pillValue, setPillValue] = useState<number>(1);
  const [barChart, setBarChart] = useState<any>({
    labels: ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"],
    datasets: [
      {
        label: "Horas",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: ["#0073A4", "#0073A4", "#0073A4", "#0073A4", "#0073A4", "#0073A4", "#0073A4"],
        borderColor: ["#0073A4", "#0073A4", "#0073A4", "#0073A4", "#0073A4", "#0073A4", "#0073A4"],
      },
    ],
  });
  const barChartOptions = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          barPercentage: 1,
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
            color: "rgba(0, 0, 0, 0.1)",
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  };
  const [pieChart, setPieChart] = useState({
    data: {
      labels: ["Aceptadas", "Rechazadas", "Sin unidades disponibles", "Unidades que no aceptaron"],
      datasets: [
        {
          data: [300, 50, 100, 40],
          backgroundColor: ["#003F5C", "#A05195", "#F95D6A", "#FFA600"],
          hoverBackgroundColor: ["#003F5C", "#A05195", "#F95D6A", "#FFA600"],
        },
      ],
    },
  });

  // SmoothScroll
  useScrollTop();

  useEffect(() => {
    const fetchCliente = async () => {
      try {
        const cliente: any = await http.get(`clientes/${clienteId}`);
        setCliente(cliente);
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al obtener los datos, intente de nuevo.</ToastMessage>);
      }
    };
    fetchCliente();
  }, [clienteId]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleClickPill = (value: number) => e => {
    setPillValue(value);
  };

  return (
    <section id="clientes">
      <MDBRow className="mb-4">
        <MDBCol className="align-self-center">
          <header>
            <h3 className="mb-0">
              <Link className="text-dark" to="/r/clientes">
                <MDBIcon className="mr-3" icon="arrow-left" />
              </Link>
              Estadísticas de cliente
            </h3>
          </header>
        </MDBCol>
      </MDBRow>

      <MDBCard>
        <AppBar className="bg-primary" position="static">
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab
              classes={{
                wrapper: "text-capitalize font-size-18",
              }}
              label="Uso de la aplicación"
            />
            <Tab
              classes={{
                wrapper: "text-capitalize font-size-18",
              }}
              label="Solicitudes"
            />
          </Tabs>
        </AppBar>
        <MDBCardBody className="px-4 pt-3 pb-4">
          {tabValue === 0 && (
            <Fragment>
              <MDBNav className="md-pills nav-justified pills-deep-blue pills-rounded">
                <MDBNavItem>
                  <a className={`nav-link ${pillValue === 1 ? "active" : ""}`} onClick={handleClickPill(1)}>
                    Hoy
                  </a>
                </MDBNavItem>
                <MDBNavItem>
                  <a className={`nav-link ${pillValue === 2 ? "active" : ""}`} onClick={handleClickPill(2)}>
                    Semana
                  </a>
                </MDBNavItem>
                <MDBNavItem>
                  <a className={`nav-link ${pillValue === 3 ? "active" : ""}`} onClick={handleClickPill(3)}>
                    Mes
                  </a>
                </MDBNavItem>
              </MDBNav>

              <hr />

              <MDBRow className="my-4">
                <MDBCol>
                  <h5 className="mb-0">05 de marzo de 2019 - 10 de marzo del 2019</h5>
                </MDBCol>
              </MDBRow>

              <hr />

              {/* GRAFICA */}
              <div className="bar-chart" style={{ height: "18rem" }}>
                <Bar data={barChart} options={barChartOptions} />
              </div>
            </Fragment>
          )}
          {tabValue === 1 && (
            <Fragment>
              <MDBNav className="md-pills nav-justified pills-deep-blue pills-rounded">
                <MDBNavItem>
                  <a className={`nav-link ${pillValue === 1 ? "active" : ""}`} onClick={handleClickPill(1)}>
                    Hoy
                  </a>
                </MDBNavItem>
                <MDBNavItem>
                  <a className={`nav-link ${pillValue === 2 ? "active" : ""}`} onClick={handleClickPill(2)}>
                    Semana
                  </a>
                </MDBNavItem>
                <MDBNavItem>
                  <a className={`nav-link ${pillValue === 3 ? "active" : ""}`} onClick={handleClickPill(3)}>
                    Mes
                  </a>
                </MDBNavItem>
              </MDBNav>

              <hr />

              <MDBRow className="my-4">
                <MDBCol>
                  <h5 className="mb-0">05 de marzo de 2019 - 10 de marzo del 2019</h5>
                </MDBCol>
              </MDBRow>

              <hr />

              {/* GRAFICAS */}
              <div className="pie-chart w-100 mb-5" style={{ height: "25rem" }}>
                <Pie data={pieChart.data} options={{ maintainAspectRatio: false }} />
              </div>

              <hr />

              <div className="bar-chart w-100 mb-5" style={{ height: "18rem" }}>
                <h5>Grafica por día</h5>
                <h6>Solicitudes aceptadas</h6>
                <Bar data={barChart} options={barChartOptions} />
              </div>
            </Fragment>
          )}
        </MDBCardBody>
      </MDBCard>
    </section>
  );
};

export default ClienteStats;

