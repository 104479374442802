import React, { useEffect, useState } from "react";
import { MDBModal, MDBModalBody, MDBModalFooter, MDBBtn } from "mdbreact";
import http from "services/http.service";
import ToastMessage from "components/shared/ToastMessage";
import { toast } from "react-toastify";
import SelectInput from "components/shared/SelectInput";

export interface AssignChoferModalProps {
  show?: boolean;
  onCancel?: () => void;
  onAccept?: () => void;
  idPeticion: number;
}

const AssignChoferModal: React.FC<AssignChoferModalProps> = ({ show, onCancel, onAccept, idPeticion }) => {
  const [choferes, setChoferes] = useState<any[]>([]);
  const [filters, setFilters] = useState({
    chofer: null as any,
  });

  // Obtener los datos de los filtros
  useEffect(() => {
    const fetchFiltros = async () => {
      try {
        // TODO: se mostraran los choferesLider inactivos?
        const { rows }: any = await http.get("/choferes", {
          params: {
            limit: 9999,
            page: 1,
          },
        });
        setChoferes(
          rows.map(option => {
            return {
              value: option[Object.keys(option)[0]],
              label: `${option.nombres} ${option.primerApellido} ${option.segundoApellido}`,
            };
          })
        );
      } catch (error) {
        if ((error.status && error.status !== 500) || error.type) {
          toast.error(<ToastMessage type={"error"}>Ocurrió un error al cargar los filtros, recargue la página.</ToastMessage>);
        }
      }
    };

    fetchFiltros();
    // eslint-disable-next-line
  }, []);

  const handleAssignChofer = async () => {
    try {
      await http.put(`/peticiones/${idPeticion}/assign`, {
        idChofer: filters.chofer,
      });
      toast.success(<ToastMessage type={"success"}>La acción fue realizada con éxito.</ToastMessage>);
    } catch (error) {
      console.error(error);
      toast.error(<ToastMessage type={"error"}>{error.message}</ToastMessage>);
    }

    onAccept?.();
  };

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <MDBModal isOpen={show}>
      <MDBModalBody>
        <h2 className="text-center">¿Asignar el viaje manualmente?</h2>
        <p className="text-center">Una vez asignado se notificara al chofer y cliente.</p>
        <SelectInput
          name="chofer"
          placeholder="Chofer"
          options={choferes}
          handleCustomSelect={inputName =>
            (option, { action }) => {
              setFilters({
                ...filters,
                [inputName]: option.value,
              });
            }}
          value={filters.chofer}
        />
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn
          className="btn btn-success waves-effect waves-light text-capitalize"
          disabled={!filters.chofer}
          onClick={handleAssignChofer}>
          Aceptar
        </MDBBtn>
        <MDBBtn className="btn btn-danger waves-effect waves-light text-capitalize" onClick={handleCancel}>
          Cancelar
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );
};

export default AssignChoferModal;

