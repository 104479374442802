import React, { useState, useRef, useEffect } from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardBody } from "mdbreact";
import polyline from "@mapbox/polyline";
import moment from "moment";
import { toast } from "react-toastify";
// Mis Componentes
import http from "services/http.service";
import ToastMessage from "components/shared/ToastMessage";
import MonitorStats from "../monitor/MonitorStats";
import MapaVehiculos from "../monitor/MapaVehiculos";
import MonitorViajesLista from "../monitor/viajes/MonitorViajesLista";
import MonitorSolicitudesLista from "../monitor/solicitudes/MonitorSolicitudesLista";
import "./MonitorPage.css";
import { getCurrentUser } from "services/authentication.service";

const initialPosition = {
  position: [29.0729673, -110.9559192],
  zoom: 12,
  polyline: [],
  initialPoint: [],
  finalPoint: [],
};

export interface MonitorPageProps {}

const MonitorPage: React.FC<MonitorPageProps> = () => {
  const user = getCurrentUser();
  // Mapa
  const mapa = useRef<any>(null);
  const [map, setMap] = useState<any>({
    ...initialPosition,
  });
  const [zona, setZona] = useState<number>(
    user.zona ? user.zona : process.env.REACT_APP_ZONA_DEFAULT ? parseInt(process.env.REACT_APP_ZONA_DEFAULT) : null
  );

  // Zoom al punto inicial de la ruta en el mapa
  useEffect(() => {
    if (map.initialPoint.length) {
      mapa.current.leafletElement.flyToBounds([map.initialPoint, map.finalPoint]);
    }
    // eslint-disable-next-line
  }, [map.initialPoint]);

  const handleVerRuta = async (viajeId: number | null, solicitudId: number | null) => {
    let polilineaViaje: any;
    if (viajeId) {
      polilineaViaje = await fetchPolilinea(viajeId, null);
    } else {
      polilineaViaje = await fetchPolilinea(null, solicitudId);
    }
    const polilineaDecoded: number[][] = polyline.decode(polilineaViaje.polilinea);
    polilineaDecoded.unshift([parseFloat(polilineaViaje.dirInicio.lat), parseFloat(polilineaViaje.dirInicio.lng)]);
    polilineaDecoded.push([parseFloat(polilineaViaje.dirFin.lat), parseFloat(polilineaViaje.dirFin.lng)]);

    setMap({
      ...map,
      polyline: polilineaDecoded,
      initialPoint: [parseFloat(polilineaViaje.dirInicio.lat), parseFloat(polilineaViaje.dirInicio.lng)],
      finalPoint: [parseFloat(polilineaViaje.dirFin.lat), parseFloat(polilineaViaje.dirFin.lng)],
    });

    // Scroll vista de mapa
    document.getElementsByClassName("mapa-monitor")[0].scrollIntoView({
      behavior: "smooth",
    });
  };

  const fetchPolilinea = async (viajeId: number | null, solicitudId: number | null) => {
    try {
      if (viajeId) {
        const viajeData: any = await http.get(`viajes/${viajeId}`);
        return viajeData.polilineaViaje;
      } else {
        const solicitudData: any = await http.get(`peticiones/${solicitudId}`);
        return solicitudData.polilinea;
      }
    } catch (error) {
      if ((error.status && error.status !== 500) || error.type) {
        toast.error(<ToastMessage type={"error"}>Ocurrió un error al obtener la ruta, intente de nuevo.</ToastMessage>);
      }
    }
  };

  const handleChangeSelect =
    inputName =>
    (option, { action }) => {
      setZona(option.value);
    };

  return (
    <section id="monitor">
      <div className="bienvenida text-dark">
        <h4 className="font-weight-medium">Buen día</h4>
        <h5>{moment().format("LL")}</h5>
      </div>

      {/* ESTADISTICAS */}
      <MonitorStats idZona={zona} />

      {/* MAPA VEHICULOS */}
      <MDBRow className="mb-4">
        <MDBCol size="12">
          <MDBCard className="mapa-monitor">
            <MDBCardBody className="p-0">
              <MapaVehiculos ref={mapa} map={map} idZona={zona} onZonaChange={handleChangeSelect} />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>

      {/* TABLA DE SOLICITUDES */}
      <MDBCard className="mb-4">
        <MonitorSolicitudesLista idZona={zona} onVerRuta={handleVerRuta} />
      </MDBCard>

      {/* TABLA DE VIAJES */}
      <MDBCard>
        <MonitorViajesLista idZona={zona} onVerRuta={handleVerRuta} />
      </MDBCard>
    </section>
  );
};

export default MonitorPage;

