import React, { useState, useEffect, useRef } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { MDBCard, MDBCardBody, MDBIcon } from "mdbreact";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import ToastMessage from "components/shared/ToastMessage";
import TabContainer from "components/shared/TabContainer";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
// Mis Componentes
import InformacionZona from "./form/InformacionZona";
import ZonaOperacion from "./form/ZonaOperacion";
import Cobranza from "./form/Cobranza";
import Comisiones from "./form/Comisiones";
import ZonaResumen from "./form/ZonaResumen";
import http from "services/http.service";
import apiErrorHandler from "services/apiErrorHandler.service";
import { mapOptionsToViewModel } from "utils";
// Mis Types
import Zona, { UsuarioOptions, Tarifa } from "models/Zonas";
import { Option } from "typings/General";
import { Pagination } from "typings/Tablas";

const InitialState: Zona = {
  nombreZona: "",
  idUsuarioAdmin: null,
  prefijoChofer: "",
  coordenadas: [],
  pagoEfectivo: false,
  pagoTarjeta: true,
  pagoMonedero: false,
  tarifas: [
    {
      uid: Math.random() * (0 - 10) + 10,
      idMetodoPago: 2,
      idTipoVehiculo: 1,
      tarifaUnica: "",
      costoKm: "",
      costoMin: "",
      banderazo: "",
      tarifaMinima: "",
      multiplicador: "",
    },
    {
      uid: Math.random() * (0 - 10) + 10,
      idMetodoPago: 2,
      idTipoVehiculo: 2,
      tarifaUnica: "",
      costoKm: "",
      costoMin: "",
      banderazo: "",
      tarifaMinima: "",
      multiplicador: "",
    },
    {
      uid: Math.random() * (0 - 10) + 10,
      idMetodoPago: 2,
      idTipoVehiculo: 4,
      tarifaUnica: "",
      costoKm: "",
      costoMin: "",
      banderazo: "",
      tarifaMinima: "",
      multiplicador: "",
    },
  ],
  comisiones: [
    {
      idTipoChofer: 1,
      efectivo: undefined,
      tarjeta: undefined,
      odometro: undefined,
    },
    {
      idTipoChofer: 2,
      efectivo: undefined,
      tarjeta: undefined,
    },
  ],
  usuarios: [],
};

const InitialPosition = {
  position: [29.0729673, -110.9559192],
  zoom: 7,
  polygonPoints: [],
};

export interface ZonaStepperProps extends RouteComponentProps {}

const ZonaStepper: React.FC<ZonaStepperProps> = ({ match, history }) => {
  // FORM STATE
  const zonaId = match.params["id"];
  const [zona, setZona] = useState<Zona>({
    ...InitialState,
  });
  const [zonaUpdate, setZonaUpdate] = useState<Zona>({
    ...InitialState,
  });

  // TABS
  const [tabValue, setTabValue] = useState<number>(0);

  // SELECTS
  const [adminZonaOptions, setAdminZonaOptions] = useState<any[]>([]);
  const [tipoVehiculoOptions, setTipoVehiculoOptions] = useState<any[]>([]);

  // TABLA
  const [usuariosOptions, setUsuariosOptions] = useState<UsuarioOptions[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<UsuarioOptions[]>([]);
  const [grupo, setGrupo] = useState<any[]>([]);
  // const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    totalSize: 10,
    sizePerPage: 10,
  });

  // MAPA
  const mapa = useRef<any>(null);
  const mapaResumen = useRef<any>(null);
  const [map, setMap] = useState<any>({
    ...InitialPosition,
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Obtener los administradores de zona
  useEffect(() => {
    const fetchAdminZonas = async () => {
      try {
        const params = {
          idTipoUsuario: 3,
          limit: 9999,
          page: 1,
        };
        const { rows }: any = await http.get("usuarios", { params });
        setAdminZonaOptions(mapAdminZonaOptionsToViewModel(rows));
      } catch (error) {
        toast.error(
          <ToastMessage type={"error"}>Ocurrió un error al cargar la lista de administradores de zona, intente de nuevo.</ToastMessage>
        );
      }
    };

    const fetchTipoVehiculos = async () => {
      try {
        const params = {
          activo: true,
          limit: 9999,
          page: 1,
        };
        const { rows }: any = await http.get("catalogos/TiposVehiculos", { params });
        setTipoVehiculoOptions(mapOptionsToViewModel(rows));
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ocurrió un error al cargar los tipos de vehiculo, intente de nuevo.</ToastMessage>);
      }
    };

    fetchAdminZonas();
    fetchTipoVehiculos();
    // eslint-disable-next-line
  }, []);

  // Obtener datos para editar zona
  useEffect(() => {
    const fetchZona = async () => {
      try {
        const zonaData = await http.get(`zonas/${zonaId}`);
        populateZona(zonaData);
      } catch (error) {
        if ((error.status && error.status !== 500) || error.type) {
          toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al obtener los datos de la zona, intente de nuevo.</ToastMessage>);
        }
      }
    };

    const populateZona = zonaEdit => {
      const zonaMapped = {
        nombreZona: zonaEdit.nombreZona,
        idUsuarioAdmin: zonaEdit.adminZona.idUsuario,
        prefijoChofer: zonaEdit.prefijoChofer ? zonaEdit.prefijoChofer : "",
        coordenadas: zonaEdit.poligonoZona.coordinates[0],
        pagoEfectivo: zonaEdit.pagoEfectivo,
        pagoTarjeta: zonaEdit.pagoTarjeta,
        pagoMonedero: zonaEdit.pagoMonedero,
        tarifas: zonaEdit.tarifasZona
          .map(tarifa => {
            return {
              idTarifa: tarifa.idTarifa,
              idMetodoPago: tarifa.metodoPago.idMetodoPago,
              idTipoVehiculo: tarifa.tipoVehiculo.idTipoVehiculo ? tarifa.tipoVehiculo.idTipoVehiculo : null,
              tarifaUnica: tarifa.tarifaUnica ? parseFloat(tarifa.tarifaUnica) : "",
              costoKm: tarifa.costoKm ? parseFloat(tarifa.costoKm) : "",
              costoMin: tarifa.costoMin ? parseFloat(tarifa.costoMin) : "",
              banderazo: tarifa.banderazo ? parseFloat(tarifa.banderazo) : "",
              tarifaMinima: tarifa.tarifaMinima ? parseFloat(tarifa.tarifaMinima) : "",
              multiplicador: tarifa.multiplicador ? parseFloat(tarifa.multiplicador) : "",
            } as Tarifa;
          })
          .sort((a, b) => a.idMetodoPago - b.idMetodoPago),
        comisiones: zonaEdit.comisionesZona.map(comision => {
          return {
            idTipoChofer: comision.tipoChofer.idTipoChofer,
            ...(comision.tipoChofer.idTipoChofer === 1 || comision.tipoChofer.idTipoChofer === 2 //Si es tipo Taxista o Privado se agrega campo efectivo
              ? { efectivo: parseFloat(comision.efectivo).toString() }
              : {}),
            tarjeta: parseFloat(comision.tarjeta).toString(),
            ...(comision.tipoChofer.idTipoChofer === 1 //Si es tipo Taxista se agrega odometro
              ? { odometro: parseFloat(comision.odometro).toString() }
              : {}),
            ...(comision.tipoChofer.idTipoChofer === 3 //Si es tipo Chofer x Viajes se agrega costoViaje
              ? { costoViaje: parseFloat(comision.costoViaje).toString() }
              : {}),
          };
        }),
        usuarios: [],
      };
      setZona(cloneDeep(zonaMapped));

      setZonaUpdate(cloneDeep(zonaMapped));

      // Mappear puntos del poligono
      const polygonPoints = zonaEdit.poligonoZona.coordinates[0].map(point => Object.values(point).reverse());
      setMap({
        ...map,
        polygonPoints,
      });
    };

    const fetchMiembros = async () => {
      const { page } = pagination;
      try {
        const params = {
          idZona: zonaId,
          idTipoUsuario: "4,6", // Administradores y Operadores
          limit: 200,
          page,
        };
        const { rows: usuarios, count: totalSize }: any = await http.get("usuarios", { params });
        setGrupo(mapMiembrosToViewModel(usuarios));
        setPagination({ ...pagination, totalSize });
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al obtener la lista de miembros, intente de nuevo.</ToastMessage>);
      }
    };

    if (zonaId) {
      fetchZona();
      fetchMiembros();
    }
    // eslint-disable-next-line
  }, [zonaId]);

  // Centrar la zona en el mapa
  useEffect(() => {
    if (map.polygonPoints.length && tabValue === 1 && zonaId) {
      mapa.current.leafletElement.fitBounds([map.polygonPoints]);
    } else if (map.polygonPoints.length && tabValue === 3) {
      mapaResumen.current.leafletElement.fitBounds([map.polygonPoints]);
    }
    // eslint-disable-next-line
  }, [tabValue]);

  // SELECT
  const mapAdminZonaOptionsToViewModel = (options: any[]): Option[] => {
    if (zonaId) {
      return options.map(option => {
        return {
          value: option[Object.keys(option)[0]],
          label: `${option.nombres} ${option.primerApellido} ${option.segundoApellido}`,
        };
      });
    }
    return options
      .filter(option => option.zona === null)
      .map(option => {
        return {
          value: option[Object.keys(option)[0]],
          label: `${option.nombres} ${option.primerApellido} ${option.segundoApellido}`,
        };
      });
  };

  // TABLA DE MIEMBROS
  const handleAddMiembro = () => {
    if (selectedOptions.length) {
      const userExist = grupo.find(usuario => usuario.idUsuario === selectedOptions[0].idUsuario);
      if (userExist) {
        toast.warn(<ToastMessage type={"warn"}>El usuario ya es parte de la zona.</ToastMessage>);
        return;
      } else {
        // Agregar al usuario al state
        setZona({
          ...zona,
          usuarios: zona.usuarios.concat({
            idUsuario: selectedOptions[0].idUsuario,
            idTipoUsuario: selectedOptions[0].tipoUsuario.idTipoUsuario,
          }),
        });
        // Agregar al usuario al state de la tabla
        const miembro = mapMiembroToViewModel(selectedOptions[0]);
        const newGrupo = grupo.concat(miembro);
        setGrupo(newGrupo);
        // toast.success(<ToastMessage type={'success'}>La acción fue realizada con éxito.</ToastMessage>);
      }
    }
    return;
  };

  const handleRemoveMiembro = async (id: number) => {
    const userExistInDB = grupo.find(usuario => usuario.idUsuario === id);
    const userExistLocally = zona.usuarios.find(usuario => usuario.idUsuario === id);
    if (zonaId && userExistInDB && !userExistLocally) {
      try {
        const result = await Swal.fire({
          title: `¿Estas seguro que deseas eliminar este miembro de la zona?`,
          text: "Puedes volver a agregarlo en caso de no estar asignado a otra zona",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-success waves-effect waves-light text-capitalize",
            cancelButton: "btn btn-danger waves-effect waves-light text-capitalize ml-2",
          },
          buttonsStyling: false,
        });
        if (result.value) {
          await http.put(`usuarios/${id}/removeZone`);

          const newGrupo = grupo.filter(usuario => usuario.idUsuario !== id);
          setGrupo(newGrupo);
        }
      } catch (error) {
        if (error.type && error.type === "User/ZoneAdmin") {
          toast.error(<ToastMessage type={"error"}>{error.message}</ToastMessage>);
          return;
        }
      }
      return;
    }
    const newGrupo = grupo.filter(usuario => usuario.idUsuario !== id);
    setGrupo(newGrupo);
    setZona({
      ...zona,
      usuarios: zona.usuarios.filter(usuario => usuario.idUsuario !== id),
    });
  };

  const handleSelectedMiembro = (selected: UsuarioOptions[]) => {
    setSelectedOptions(selected);
  };

  const fetchSearchUsuarios = async (query: string) => {
    const { page, sizePerPage: limit } = pagination;
    try {
      const params = {
        ...(query.trim() ? { search: query.trim() } : {}),
        idTipoUsuario: "4,6", // Administradores y Operadores
        limit,
        page,
      };
      const { rows: usuarios, count: totalSize }: any = await http.get("usuarios", { params });
      // Mostrar solo usuarios sin zona asignada
      setUsuariosOptions(usuarios.filter(usuario => usuario.zona === null));
      setPagination({ ...pagination, totalSize });
    } catch (error) {
      toast.error(<ToastMessage type={"error"}>Ocurrió un error al buscar usuarios, intente de nuevo.</ToastMessage>);
    }
  };

  const mapMiembroToViewModel = (usuario: UsuarioOptions) => {
    return {
      idUsuario: usuario.idUsuario,
      nombre: `${usuario.nombres} ${usuario.primerApellido} ${usuario.segundoApellido}`,
      email: usuario.email,
      rol: usuario.tipoUsuario.nombre,
    };
  };

  const mapMiembrosToViewModel = (usuarios: UsuarioOptions[]) => {
    return usuarios.map(usuario => {
      return {
        idUsuario: usuario.idUsuario,
        nombre: `${usuario.nombres} ${usuario.primerApellido} ${usuario.segundoApellido}`,
        email: usuario.email,
        rol: usuario.tipoUsuario.nombre,
      };
    });
  };

  const handleTableChange = (type, { page, sizePerPage }) => {
    setPagination({
      ...pagination,
      page,
    });
  };

  // MAPA
  const handleAddPolygonPoint = event => {
    const polygonPoints = map.polygonPoints.concat(event.latlng);
    setMap({
      ...map,
      polygonPoints,
    });

    const coordenadas = polygonPoints.map(point => Object.values(point).reverse());
    setZona({
      ...zona,
      coordenadas,
    });
  };

  const handleResetPolygon = () => {
    setMap({
      ...map,
      polygonPoints: InitialState.coordenadas,
    });

    setZona({
      ...zona,
      coordenadas: InitialState.coordenadas,
    });
  };

  // SWITCH
  const handleSwitchChange = (inputName, setFieldValue, tarifas: Tarifa[], unshift, remove) => async (event, checked) => {
    // Setear el valor en formik state
    setFieldValue(inputName, checked);
    const commonTarifaProps = {
      tarifaUnica: "",
      costoKm: "",
      costoMin: "",
      banderazo: "",
      tarifaMinima: "",
      multiplicador: "",
    };

    switch (inputName) {
      case "pagoEfectivo":
        const newTarifasEfectivo = [
          {
            uid: Math.random() * (0 - 10) + 10,
            idMetodoPago: 1,
            idTipoVehiculo: 1,
            ...commonTarifaProps,
          },
          {
            uid: Math.random() * (0 - 10) + 10,
            idMetodoPago: 1,
            idTipoVehiculo: 2,
            ...commonTarifaProps,
          },
          {
            uid: Math.random() * (0 - 10) + 10,
            idMetodoPago: 1,
            idTipoVehiculo: 4,
            ...commonTarifaProps,
          },
        ];
        if (checked) {
          // Agregar al state de formik
          for (let tarifaEfectivo of newTarifasEfectivo) {
            unshift(tarifaEfectivo);
          }
        } else {
          // Eliminar del state de formik
          for (let index of tarifas.filter(tarifa => tarifa.idMetodoPago === 1)) {
            // console.log(
            //    'TARIFAS?',
            //    tarifas.filter(tarifa => tarifa.idMetodoPago === 1)
            // );
            remove(index);
          }
        }
        break;
      case "pagoTarjeta":
        const newTarifasTarjeta = [
          {
            uid: Math.random() * (0 - 10) + 10,
            idMetodoPago: 2,
            idTipoVehiculo: 1,
            ...commonTarifaProps,
          },
          {
            uid: Math.random() * (0 - 10) + 10,
            idMetodoPago: 2,
            idTipoVehiculo: 2,
            ...commonTarifaProps,
          },
          {
            uid: Math.random() * (0 - 10) + 10,
            idMetodoPago: 2,
            idTipoVehiculo: 4,
            ...commonTarifaProps,
          },
        ];
        if (checked) {
          // Agregar al state de formik
          for (let tarifaTarjeta of newTarifasTarjeta) {
            unshift(tarifaTarjeta);
          }
        } else {
          // Eliminar del state de formik
          for (let index of tarifas.filter(tarifa => tarifa.idMetodoPago === 2)) {
            remove(index);
          }
        }
        break;
    }
  };

  // BUTTONS HANDLERS
  const handleDeleteTarifa = async (idTarifa: number, idMetodoPago: number) => {
    try {
      const result = await Swal.fire({
        title: `¿Estás seguro que deseas eliminar esta tarifa?`,
        // text: 'Puedes revertir este cambio en cualquier momento presionando el mismo boton',
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-success waves-effect waves-light text-capitalize",
          cancelButton: "btn btn-danger waves-effect waves-light text-capitalize ml-2",
        },
        buttonsStyling: false,
      });
      if (result.value) {
        await http.delete(`zonas/${idTarifa}/tarifas`);

        const tarifasLength = zona.tarifas.filter(tarifa => tarifa.idMetodoPago === idMetodoPago).length;
        // Eliminar del state
        if (tarifasLength === 1) {
          setZona({
            ...zona,
            ...(idMetodoPago === 1 ? { pagoEfectivo: false } : { pagoTarjeta: true }),
            tarifas: zona.tarifas.filter(tarifa => tarifa.idTarifa !== idTarifa),
          });
          return;
        }
        setZona({
          ...zona,
          tarifas: zona.tarifas.filter(tarifa => tarifa.idTarifa !== idTarifa),
        });
      }
    } catch (error) {
      toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al eliminar la tarifa, intente de nuevo.</ToastMessage>);
    }
  };

  const handleBackBtnClick = () => setTabValue(tabValue => tabValue - 1);

  const handleCheckStep = () => {
    // Validar que seleccionen al menos 3 puntos
    if (tabValue === 1 && map.polygonPoints.length < 3) {
      toast.warn(<ToastMessage type={"warn"}>Seleccione al menos 3 puntos.</ToastMessage>);
      return;
    }

    // Checar si ya se encuentra agregada la coordenada inicial como coordenada final
    const coordenadaFound = zona.coordenadas[0][0] === zona.coordenadas[zona.coordenadas.length - 1][0];
    if (!coordenadaFound) {
      // Agregar la coord inicial como final para geometry de mysql
      const coordenadas = zona.coordenadas.concat([zona.coordenadas[0]]);
      setZona({
        ...zona,
        coordenadas,
      });
    }
    setTabValue(2);
  };

  const handleStepSubmit = (values: Zona) => {
    setZona({
      ...zona,
      ...values,
    });
    setTabValue(tabValue => tabValue + 1);
  };

  const handleFormSubmit = async () => {
    try {
      setIsSubmitting(true);
      zonaId ? await updateZona() : await createZona();
    } catch (error) {
      setIsSubmitting(false);
      apiErrorHandler("Zona", error);
    }
  };

  const updateZona = async () => {
    const body = setBodyPUT();
    await http.put(`zonas/${zonaId}`, body);
    const result = await Swal.fire({
      title: "Datos actualizados",
      text: "Los cambios han sido actualizados correctamente.",
      icon: "success",
      showCancelButton: false,
      confirmButtonText: "Aceptar",
      allowOutsideClick: false,
      customClass: {
        confirmButton: "btn btn-info waves-effect waves-light text-capitalize",
      },
      buttonsStyling: false,
    });
    if (result) {
      history.push(`/r/zonas`);
    }
  };

  const setBodyPUT = () => {
    const body: object = {};
    for (const key of Object.keys(zona)) {
      if (Array.isArray(zona[key])) {
        // Comparar estado actual con anterior del arreglo
        if (!isEqual(zona[key], zonaUpdate[key])) {
          if (key === "tarifas") {
            let tarifasTemp: any[] = [];
            for (const [index, tarifa] of zona["tarifas"].entries()) {
              let indexUp: number | undefined = undefined;
              if (tarifa.idTarifa) {
                indexUp = zonaUpdate["tarifas"].findIndex(tarifaUp => tarifaUp.idTarifa === tarifa.idTarifa);
              } else {
                indexUp = -1;
              }
              // Si cumple entonces el tipo de tarifa ya estaba en el arreglo de tarifas
              if (indexUp >= 0) {
                // Checar si el elemento actual del arreglo es igual al elemento anterior
                if (!isEqual(zona.tarifas[index], zonaUpdate.tarifas[indexUp])) {
                  let tarifaMapped = {};
                  tarifaMapped["idMetodoPago"] = zona.tarifas[index].idMetodoPago;
                  for (const [key, value] of Object.entries(zona.tarifas[index])) {
                    //Estos 2 valores no importan al actualizar
                    if (key === "idMetodoPago" || key === "idTarifa") {
                      continue;
                    } else if ((zona.tarifas[index][key] !== zonaUpdate.tarifas[index][key] && value !== "") || key === "idTipoVehiculo") {
                      tarifaMapped[key] = value;
                    }
                  }
                  // Guardar elemento en el arreglo temporal de tarifas
                  tarifasTemp.push(tarifaMapped);
                  // Guardar el arreglo modificado para enviar al backend
                  body["tarifas"] = tarifasTemp;
                }
              } else {
                // Se agrego un nuevo tipo de tarifa
                let tarifaMapped = {};
                for (const [key, value] of Object.entries(zona.tarifas[index])) {
                  // Ignorar el uid ya que solo se utiliza en UI
                  if (key === "uid") continue;

                  tarifaMapped = {
                    ...tarifaMapped,
                    ...(value ? { [`${key}`]: value } : {}),
                  };
                }
                // Guardar elemento en el arreglo temporal de tarifas
                tarifasTemp.push(tarifaMapped);
                // Guardar el arreglo modificado para enviar al backend
                body["tarifas"] = tarifasTemp;
              }
            }
          } else if (key === "comisiones") {
            let comisionesTemp: any[] = [];
            for (const index of zona["comisiones"].keys()) {
              // Checar si el elemento actual del arreglo es igual al elemento anterior
              if (!isEqual(zona.comisiones[index], zonaUpdate.comisiones[index])) {
                let comisionMapped = {};
                for (const [key, value] of Object.entries(zona.comisiones[index])) {
                  comisionMapped["idTipoChofer"] = zona.comisiones[index].idTipoChofer;
                  // Checar si los valores del objeto comision son diferentes
                  if (zona.comisiones[index][key] !== zonaUpdate.comisiones[index][key]) {
                    comisionMapped[key] = value;
                  }
                }
                // Guardar elemento en el arreglo temporal de comisiones
                comisionesTemp.push(comisionMapped);
                // Guardar el arreglo modificado para enviar al backend
                body["comisiones"] = comisionesTemp;
              }
            }
          } else if (key === "usuarios") {
            // Guardar el arreglo modificado para enviar al backend
            body["usuarios"] = zona["usuarios"];
          } else if (key === "coordenadas") {
            // Guardar el arreglo modificado para enviar al backend
            body["coordenadas"] = zona["coordenadas"];
          }
        } else {
          // Si son iguales se omite el arreglo
          continue;
        }
      } else if (zona[key] !== zonaUpdate[key]) {
        body[key] = zona[key];
      }
    }
    return body;
  };

  const createZona = async () => {
    const body = setBodyPOST();
    const newZona: any = await http.post("zonas", body);
    const result = await Swal.fire({
      title: "Zona guardada con exito!",
      text: "La nueva zona ha sido guardada.",
      icon: "success",
      showCancelButton: true,
      confirmButtonText: "Crear otra Zona",
      cancelButtonText: "Ver detalles",
      allowOutsideClick: false,
      customClass: {
        confirmButton: "btn btn-info waves-effect waves-light text-capitalize",
        cancelButton: "btn btn-info waves-effect waves-light text-capitalize ml-2",
      },
      buttonsStyling: false,
    });
    if (result.value) {
      // Ir al paso 1
      setTabValue(0);
      // Reset form
      setZona({
        ...InitialState,
      });
      setGrupo([]);
      return;
    }
    history.push(`/r/zonas/${newZona.idZona}/detalles`);
  };

  const setBodyPOST = () => {
    const body: object = {};
    for (const key of Object.keys(zona)) {
      if (Array.isArray(zona[key])) {
        if (key === "tarifas") {
          let tarifasTemp: any[] = [];
          for (const index of zona["tarifas"].keys()) {
            let tarifaMapped = {};
            for (const [key, value] of Object.entries(zona.tarifas[index])) {
              // Ignorar el uid ya que solo se utiliza en UI
              if (key === "uid") continue;

              tarifaMapped = {
                ...tarifaMapped,
                ...(value ? { [`${key}`]: value } : {}),
              };
            }
            // Guardar elemento en el arreglo temporal de tarifas
            tarifasTemp.push(tarifaMapped);
            // Guardar el arreglo para enviar al backend
            body["tarifas"] = tarifasTemp;
          }
        } else if (key === "comisiones") {
          let comisionesTemp: any[] = [];
          for (const index of zona["comisiones"].keys()) {
            let comisionMapped = {};
            for (const [key, value] of Object.entries(zona.comisiones[index])) {
              comisionMapped = {
                ...comisionMapped,
                ...(value ? { [`${key}`]: value } : {}),
              };
            }
            // Guardar elemento en el arreglo temporal de tarifas
            comisionesTemp.push(comisionMapped);
            // Guardar el arreglo para enviar al backend
            body["comisiones"] = comisionesTemp;
          }
        } else if (key === "usuarios") {
          // Si agregaron usuarios, se envia el arreglo
          if (zona["usuarios"].length) {
            body["usuarios"] = zona["usuarios"];
          }
        } else if (key === "coordenadas") {
          body["coordenadas"] = zona["coordenadas"];
        }
      } else {
        body[key] = zona[key];
      }
    }
    return body;
  };

  if (zonaId && (!zona.idUsuarioAdmin || !adminZonaOptions.length)) {
    return null;
  }
  return (
    <section id="zonas">
      <header className="mb-4">
        <h3 className="mb-0">
          <Link className="text-dark" to="/r/zonas">
            <MDBIcon className="mr-3" icon="arrow-left" />
          </Link>
          {zonaId ? "Editar zona de operación" : "Nueva zona de operación"}
        </h3>
      </header>

      <MDBCard>
        <MDBCardBody>
          <Tabs
            classes={{
              indicator: "#EB6C40",
            }}
            scrollButtons="auto"
            variant="fullWidth"
            value={tabValue}>
            <Tab label="1. Información de zona" />
            <Tab label="2. Zona de operación" />
            <Tab label="3. Cobranza" />
            {/* <Tab label="4. Comisiones" /> */}
            <Tab label="4. Resumen" />
          </Tabs>
          <div className="p-4">
            {tabValue === 0 && (
              <TabContainer>
                <InformacionZona
                  zona={zona}
                  grupo={grupo}
                  usuarios={usuariosOptions}
                  administradoresZona={adminZonaOptions}
                  onAddMiembro={handleAddMiembro}
                  onRemoveMiembro={handleRemoveMiembro}
                  // isTableLoading={isTableLoading}
                  pagination={pagination}
                  onTableChange={handleTableChange}
                  fetchSearchUsuarios={fetchSearchUsuarios}
                  onSelectOption={handleSelectedMiembro}
                  onStepSubmit={handleStepSubmit}
                />
              </TabContainer>
            )}
            {tabValue === 1 && (
              <TabContainer>
                <ZonaOperacion
                  ref={mapa}
                  map={map}
                  onAddPolygonPoint={handleAddPolygonPoint}
                  onResetPolygon={handleResetPolygon}
                  onBackBtnClick={handleBackBtnClick}
                  onCheckStep={handleCheckStep}
                />
              </TabContainer>
            )}
            {tabValue === 2 && (
              <TabContainer>
                <Cobranza
                  zona={zona}
                  isZonaEdit={zonaId ? true : false}
                  tipoVehiculos={tipoVehiculoOptions}
                  onSwitchChange={handleSwitchChange}
                  onDeleteTarifa={handleDeleteTarifa}
                  onBackBtnClick={handleBackBtnClick}
                  onStepSubmit={handleStepSubmit}
                />
              </TabContainer>
            )}
            {/* {tabValue === 3 && (
              <TabContainer>
                <Comisiones zona={zona} onBackBtnClick={handleBackBtnClick} onStepSubmit={handleStepSubmit} />
              </TabContainer>
            )} */}
            {tabValue === 3 && (
              <TabContainer>
                <ZonaResumen
                  ref={mapaResumen}
                  zona={zona}
                  map={map}
                  administradoresZona={adminZonaOptions}
                  tipoVehiculos={tipoVehiculoOptions}
                  onBackBtnClick={handleBackBtnClick}
                  onFormSubmit={handleFormSubmit}
                  isSubmitting={isSubmitting}
                />
              </TabContainer>
            )}
          </div>
        </MDBCardBody>
      </MDBCard>
    </section>
  );
};

export default ZonaStepper;

