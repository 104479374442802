import React, { useState, useEffect, useCallback, Fragment } from "react";
import { MDBRow, MDBCol, MDBIcon, MDBInput, MDBBtn } from "mdbreact";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
// Mis Componentes
import ToastMessage from "components/shared/ToastMessage";
import Tooltip from "components/shared/Tooltip";
import http from "services/http.service";
import useDebounce from "hooks/useDebounce";
// Mis Types
import { Pagination } from "typings/Tablas";
import ZonasTabla from "./ZonasTabla";

export interface ZonasListaProps {}

const ZonasLista: React.FC<ZonasListaProps> = () => {
  const [zonas, setZonas] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search.trim(), 500);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    totalSize: 10,
    sizePerPage: 10,
  });

  useEffect(() => {
    fetchZonas();
    // eslint-disable-next-line
  }, [debouncedSearch, pagination.page]);

  const fetchZonas = async () => {
    const { page, sizePerPage: limit } = pagination;
    try {
      setIsTableLoading(true);

      const params = {
        ...(debouncedSearch && { search: debouncedSearch }),
        limit,
        page,
      };
      const { rows: zonasList, count: totalSize }: any = await http.get("zonas", { params });
      setZonas(mapZonasToViewModel(zonasList));
      setPagination({ ...pagination, totalSize });

      setIsTableLoading(false);
    } catch (error) {
      setIsTableLoading(false);
      toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al obtener los datos de las zonas, intente de nuevo.</ToastMessage>);
    }
  };

  const mapZonasToViewModel = (zonas: any[]) => {
    return zonas.map(zona => {
      return {
        idZona: zona.idZona,
        nombreZona: zona.nombreZona,
        nombreAdmin: `${zona.adminZona.nombres} ${zona.adminZona.primerApellido} ${zona.adminZona.segundoApellido}`,
        totalChoferes: zona.totalChoferes,
        estado: zona.activo,
      };
    });
  };

  const handleSearchChange = ({ currentTarget: input }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(input.value);
  };

  const handleRefresh = () => fetchZonas();

  const handleCleanFilters = () => {
    setSearch("");
  };

  const handleToggleEstado = useCallback(
    async (id: number, activo: boolean, index: number) => {
      try {
        const result = await Swal.fire({
          title: `¿Estás seguro que deseas ${activo ? "desactivar" : "activar"} la zona?`,
          text: "Puedes revertir este cambio en cualquier momento presionando el mismo boton",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-success waves-effect waves-light text-capitalize",
            cancelButton: "btn btn-danger waves-effect waves-light text-capitalize ml-2",
          },
          buttonsStyling: false,
        });
        if (result.value) {
          await http.put(`zonas/${id}/${activo ? "desactivar" : "activar"}`);

          // Actualizar UI
          let zonasAux = [...zonas];
          zonasAux[index].estado = !zonasAux[index].estado;
          setZonas(zonasAux);
        }
      } catch (error) {
        toast.error(<ToastMessage type={"error"}>Ha ocurrido un error al cambiar el estado, intente de nuevo.</ToastMessage>);
      }
    },
    [zonas]
  );

  const handleTableChange = useCallback(
    (type, { page, sizePerPage }) => {
      setPagination({
        ...pagination,
        page,
      });
    },
    [pagination]
  );

  return (
    <Fragment>
      <MDBRow className="table-filters py-2 mb-2">
        <MDBCol md="9">
          <MDBInput
            className="m-0"
            label="Buscar por nombre, administrador"
            outline
            icon="search"
            iconSize="lg"
            onChange={handleSearchChange}
            value={search}
          />
        </MDBCol>
        <MDBCol md="3">
          <div style={{ marginTop: "0.6rem" }}>
            <Tooltip title="Actualizar" placement="top">
              <MDBBtn size="sm" color="danger" onClick={handleRefresh}>
                <MDBIcon size="2x" icon="sync" fixed />
              </MDBBtn>
            </Tooltip>
            <Tooltip title="Limpiar Filtros" placement="top">
              <MDBBtn size="sm" color="danger" onClick={handleCleanFilters}>
                <MDBIcon size="2x" icon="eraser" fixed />
              </MDBBtn>
            </Tooltip>
          </div>
        </MDBCol>
      </MDBRow>

      <ZonasTabla
        zonas={zonas}
        isTableLoading={isTableLoading}
        pagination={pagination}
        handleTableChange={handleTableChange}
        handleToggleEstado={handleToggleEstado}
      />
    </Fragment>
  );
};

export default ZonasLista;

